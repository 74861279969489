import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import React from 'react';

import PrimaryButton from '../button/Primary';

function AbandonDialog({ onClose }: AbandonProps) {
    return (
        <Dialog
            open={true}
            maxWidth={'xs'}
            fullWidth={true}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Leave this tour?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This tour will not be saved.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={() => onClose()}>Keep Editing</PrimaryButton>
                <PrimaryButton onClick={() => onClose(true)}>Leave</PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}

type AbandonProps = {
    onClose: Function;
};

export default AbandonDialog;
