/* eslint-disable react-hooks/exhaustive-deps */
import './Pannellum.css';
import './Lightbox.css';

import { CircularProgress, Typography } from '@material-ui/core';
import { Pannellum } from 'pannellum-react';
import Lightbox from 'react-awesome-lightbox';
import React, { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getCroppedImage } from '../../services/cropImage';

import logoBlue from '../../assets/logos/logo-blue.png';
import logo from '../../assets/logos/logo.png';
import Header from '../../components/navigation/Header';
import { TourActions } from '../../redux/actions/tour';
import { RootState } from '../../redux/store';
import { getImage, invertYawPosition } from '../../services/utils';
import { colors } from '../../Theme';
import { Hotspot, Photosphere } from '../../types/tour';
import SceneDrawer from '../drawer/Drawer';
import { useStyles } from './Scene.style';
import SceneSelector from './SceneSelector';

function Scene({ tour, fetchTour, user, }: SceneProps) {
    const classes = useStyles();
    const history = useHistory();

    const [yaw, setYaw] = useState(0);
    const [pitch, setPitch] = useState(0);
    const [selectedPhotosphere, setSelectedPhotosphere] = useState<Photosphere>();
    const [selectedHotspot, setSelectedHotspot] = useState<Hotspot>();
    const [showHotspot, setShowHotspot] = useState(false);

    const [showSceneDialog, setShowSceneDialog] = useState(false);
    const [bigImage, setBigImage] = useState<string>();
    const [smallImage, setSmallImage] = useState<string>();

    const [bigImagePollCount, setBigImagePollCount] = useState(0);
    const [smallImagePollCount, setSmallImagePollCount] = useState(0);


    const params: { tourID?: string; sceneID?: string } = useParams();
    const tourID = params?.tourID;
    const sceneID = params?.sceneID;

    const location = useLocation();
    const isPublic = location?.pathname.includes('public');
    const isOwner = user?.isAdmin ? true : (tour?.user_id === user?.attributes?.sub);

    useEffect(() => {
        if (!tour && tourID) fetchTour(tourID, isPublic);
        if (!sceneID) toggleSceneDialog();
    }, []);

    useEffect(() => {
        if (tour && sceneID) {
            const selected = tour?.photospheres?.find(({ id }) => id === sceneID);
            if (selected && selected.image) {
                handleSelectedPhotosphere(selected);
                setBigImage(undefined);
                setSmallImage(undefined);
                if(selectedPhotosphere?.streetview_pano_id === null) {
                    pollForBigImage();
                    pollForSmallImage();
                }
            } else {
                toggleSceneDialog();
            }
        } else if (!sceneID) {
            setSelectedPhotosphere(undefined);
        }
    }, [tour, sceneID]);

    useEffect(() => {
        if (tourID && sceneID) {
            // Every second keep fetching image until it is found
            if (!bigImage) setTimeout(() => pollForBigImage(), 2000);
        }
    }, [bigImagePollCount]);

    useEffect(() => {
        if (tourID && sceneID) {
            // Every second keep fetching image until it is found
            if (!smallImage) setTimeout(() => pollForSmallImage(), 2000);
        }
    }, [smallImagePollCount]);

    const pollForBigImage = async () => {
        if (!tourID || !sceneID) return;

        // const image = await TourActions.checkForPhotosphere(tourID, sceneID, isPublic);
        const selected = tour?.photospheres?.find(({ id }) => id === sceneID);
        const image = getImage(selected?.image, 'medium');

        if (image) {
            if (!selectedPhotosphere?.is_megastereo){
                setBigImage(image);
            } else {
                const cropped = await getCroppedImage(image);
                setBigImage(cropped);
            }
        } else {
            setBigImagePollCount(bigImagePollCount + 1);
        }
    };

    const pollForSmallImage = async () => {
        if (!tourID || !sceneID) return;

        // needs to investigate why it attempts to refresh the image
        // const image = await TourActions.checkForThumbnail(tourID, sceneID, isPublic);
        const selected = tour?.photospheres?.find(({ id }) => id === sceneID);
        const image = getImage(selected?.image, 'thumbnail');

        if (image) {
            if (!selectedPhotosphere?.is_megastereo){
                setSmallImage(image);
            } else {
                const cropped = await getCroppedImage(image);
                setSmallImage(cropped);
            }
        } else {
            setSmallImagePollCount(bigImagePollCount + 1);
        }
    };

    const handleSelectedPhotosphere = async (photosphere: Photosphere) => {
        setSelectedPhotosphere(photosphere);
    };

    const panImage: any = useRef(null);

    const hotspotIcon = (hotSpotDiv: any, hotspot: Hotspot) => {
        const image = document.createElement('img');
        image.classList.add('image');
        // image.setAttribute('height', '80');
        // image.setAttribute('width', 'auto');
        image.setAttribute('height', '100');
        image.setAttribute('width', '100');

        const isSelected = hotspot.id === selectedHotspot?.id;

        const imageSrc = getImage(hotspot.image, 'thumbnail');
        if (imageSrc) {
            image.setAttribute('src', imageSrc);
            if (isSelected) {
                image.setAttribute('style', `border: 5px solid ${colors.tealBlue}`);
            }
        } else {
            image.setAttribute('src', isSelected ? logoBlue : logo);
        }

        hotSpotDiv.appendChild(image);
    };

    const renderHotSpot = (hotspot: Hotspot) => {
        return (
            <Pannellum.Hotspot
                key={hotspot.id}
                id={hotspot.id}
                pitch={hotspot.pitch}
                yaw={invertYawPosition(hotspot.yaw)}
                type="custom"
                handleClick={() => onSelectHotspot(hotspot, true)}
                tooltip={(div: any) => hotspotIcon(div, hotspot)}
            />
        );
    };

    const onSelectHotspot = (hotspot?: Hotspot, withRefresh = false) => {
        if (!hotspot?.id) return;

        setSelectedHotspot(hotspot);

        if (withRefresh) {
            setShowHotspot(true)
            // setTimeout(() => panImage.current.forceRender(), 0);
        }
    };

    const toggleSceneDialog = (newScene?: boolean) => {
        if (!newScene && showSceneDialog && !selectedPhotosphere?.id) {
            history.goBack();
        }

        setShowSceneDialog(!showSceneDialog);
    };

    const renderStreetView = () => {
        return (
            <div>
                <Typography variant="h4">Google StreetView Scene</Typography>
                <br />

                <Typography>
                    We are not currently allowed to import Google StreetView scenes directly (but
                    we&apos;re working on it!). This scene will not be shown to other users.
                </Typography>
                <Typography component={'span'} >
                    If you would like to add this Scene image directly (and have the right) , please
                    follow these quick and simple instructions:
                    <ol>
                        <li>
                            Go to{' '}
                            <a
                                href="https://svd360.istreetview.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                svd360.istreetview.com
                            </a>
                        </li>
                        <li>Download and install the free software</li>
                        <li>
                            Use the Panorama ID{' '}
                            {selectedPhotosphere?.streetview_pano_id
                                ? `${selectedPhotosphere?.streetview_pano_id}`
                                : null}
                            to find your image
                        </li>
                        <li>
                            Choose a download location on your computer, select
                            &apos;6656x3328&apos; as the Resolution and then click Download Panorama
                        </li>
                        <li>Upload the image as the background to this scene</li>
                    </ol>
                </Typography>
            </div>
        );
    };

    const hotspots = [...(selectedPhotosphere?.hotspots ?? [])];

    const startPitch = selectedPhotosphere?.pitch ?? pitch;
    const startYaw = selectedPhotosphere?.yaw ?? yaw;

    const currentPitch = selectedHotspot?.pitch ?? startPitch;
    const currentYaw = invertYawPosition(selectedHotspot?.yaw) ?? startYaw;

    return (
        <div className={classes.root}>
            <SceneDrawer
                sceneID={selectedPhotosphere?.id}
                selectedHotspotID={selectedHotspot?.id}
                onSelectHotspot={onSelectHotspot}
                showSceneDialog={showSceneDialog}
                toggleSceneDialog={toggleSceneDialog}
                croppedImage={smallImage}
                isPublic={isPublic}
                isOwner={isOwner}
                pitch={pitch}
                yaw={yaw}
            />

            <main className={classes.container}>
                <Header />

                {bigImage ? (
                    <Pannellum
                        width="100%"
                        height="100%"
                        image={bigImage}
                        pitch={currentPitch}
                        yaw={currentYaw}
                        hfov={110}
                        autoLoad
                        maxPitch={130}
                        minPitch={-130}
                        ref={panImage}
                        compass={true}
                        hotspotDebug={false}
                        onMouseup={() => {
                            setPitch(panImage.current.getViewer().getPitch());
                            setYaw(panImage.current.getViewer().getYaw());
                        }}
                    >
                        {hotspots.map(renderHotSpot)}
                    </Pannellum>
                ) : selectedPhotosphere?.streetview_pano_id != null ? (
                    renderStreetView()
                ) : (
                    <CircularProgress size={20} />
                )}

                <SceneSelector
                    page={'scene'}
                    photospheres={tour?.photospheres}
                    selectedSceneID={sceneID}
                    toggleSceneDialog={toggleSceneDialog}
                />

                { showHotspot && (
                    <Lightbox
                        image={selectedHotspot?.image?.original}
                        title={selectedHotspot?.title}
                        allowRotate={false}
                        onClose={() => setShowHotspot(false)} />
                )}

            </main>
        </div>
    );
}

const mapState = (state: RootState) => {
    return {
        user: state.user.user,
        tour: state.tour.tour,
    };
};

const mapDispatch = {
    fetchTour: (tourID: string, isPublic: boolean) => TourActions.fetchTour(tourID, isPublic),
};

const connector = connect(mapState, mapDispatch);
type SceneProps = ConnectedProps<typeof connector>;

export default connector(Scene);
