import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';

import { useStyles } from './Button.style';

const RaisedButton = ({ children, className = {}, loading, ...props }: any) => {
    const classes = useStyles();

    const classesArray = [classes.button, classes.raised, className];

    return (
        <Button className={classesArray.join(' ')} variant="contained" {...props}>
            {loading ? <CircularProgress size={20} /> : children}
        </Button>
    );
};

export default RaisedButton;
