import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';

import PrimaryButton from '../button/Primary';
import UploadDropzone from '../dropzone/Dropzone';

function ImageDialog({ onClose, onAddFile }: ImageDialogProps) {
    const [newImage, setNewImage] = useState<BinaryType>();

    const onAdd = () => {
        onAddFile(newImage, 'HS_OVERLAY_IMAGE');
    };

    const onDropFile = (file: BinaryType) => {
        setNewImage(file);
    };

    return (
        <Dialog
            open={true}
            maxWidth={'sm'}
            fullWidth={true}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Add image overlay</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Once an image is uploaded, position it in the scene
                </DialogContentText>

                <UploadDropzone fileType="image" onDropFile={onDropFile} />
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={() => onClose()}>Cancel</PrimaryButton>
                <PrimaryButton disabled={!newImage} onClick={onAdd}>
                    Add
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}

type ImageDialogProps = {
    onClose: Function;
    onAddFile: Function;
};

export default ImageDialog;
