import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    counter: {
        textAlign: 'right',
        marginTop: theme.spacing(1) / 2,
        color: theme.palette.primary.main,
        fontSize: 12,
    },
}));
