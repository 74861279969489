import axios from 'axios';

import config from '../../config/app';
import { AsyncThunkAction } from '../../types/thunk';
import { categoryConstants } from './types';

const baseUrl = config.apiUrl;

interface cat {
    id: string;
    value: string;
}

const fetchCategories = (): AsyncThunkAction => {
    return async dispatch => {
        try {
            dispatch({
                type: categoryConstants.LOADING_CATEGORIES,
            });

            const url = `${baseUrl}/category`;
            const categories = await axios.get(url);

            dispatch({
                type: categoryConstants.FETCH_CATEGORIES,
                payload: categories.data.sort((a:cat,b:cat) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)),
            });
        } catch (error) {
            console.log(error);
            console.log('Error fetching categories');
        }
    };
};

export const CategoryActions = {
    fetchCategories,
};
