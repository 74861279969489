import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../Theme';

export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    listTile: {
        minWidth: 400,
        '&:hover': {
            opacity: 0.9,
            cursor: 'pointer',
        },
    },
    titleBar: {
        height: 100,
        paddingTop: theme.spacing(4),
        background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    coverPhoto: {
        backgroundColor: theme.palette.grey[700],
    },
    icon: {
        color: colors.white,
        flex: 1,
        marginRight: theme.spacing(2),
    },
}));
