import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import React from 'react';

import PrimaryButton from '../button/Primary';

function DeleteConfirmDialog({ onClose, item }: DialogProps) {
    return (
        <Dialog
            open={true}
            maxWidth={'xs'}
            fullWidth={true}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Deleting {item}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you wish to delete {item}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={() => onClose()}>Cancel</PrimaryButton>
                <PrimaryButton onClick={() => onClose(true)}>Confirm</PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}

type DialogProps = {
    onClose: Function;
    item: 'tour' | 'scene' | 'hotspot';
};

export default DeleteConfirmDialog;
