import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import googleButton from '../../assets/images/google-signin-button.png';
import loginImage from '../../assets/images/login-bg-1280.jpg';

export const useStyles = makeStyles(theme => ({
    centeredPage: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    bgImage: {
        backgroundImage: `url(${loginImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',

    },
    formWrapper: {
        margin: theme.spacing(3),
        minWidth: 400,
    },
    spacing: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    resultText: {
        color: blue[400],
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
    },
    googleButton: {
        backgroundImage: `url(${googleButton})`,
        width: 191,
        height: 46,
    },
    termsPage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(8),
    },
    termsText: {
        width: 800,
    },
    actionContainer: {
        width: 800,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
