/* eslint-disable react-hooks/rules-of-hooks */
import {
    ImageList,
    ImageListItem,
    ImageListItemBar,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    useMediaQuery,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useState } from 'react';

import emptyCoverPhoto from '../../assets/images/transparentBox.png';
import { getImage } from '../../services/utils';
import { Tour } from '../../types/tour';
import { useStyles } from './Grid.style';

const Grid = ({ tours, onClick, displayMenu, onDeleteTour, isOwner }: GridProps) => {
    // isOwner is not correctly labeled has it only applies for the Public listing
    const classes = useStyles();
    const [openTile, setOpenTile] = useState<null | string>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const onTileClick = (tourID: string, firstSceneID: string) => {
        if (menuOpen) return;
        onClick(tourID, firstSceneID);
    };

    const onDeleteClick = () => {
        const tourID = openTile;
        if (!tourID) return;

        if (menuOpen) handleClose();
        setOpenTile(null);
        if (onDeleteTour) onDeleteTour(tourID);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>, tourID: string) => {
        event.stopPropagation();
        setOpenTile(tourID);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getWidthCols = () => {
        const medium = useMediaQuery('(max-width:960px)');
        const small = useMediaQuery('(max-width:600px)');
        if (medium) return 2;
        if (small) return 1;
        return 3;
    };

    const renderImage = (tour: Tour, firstScene: any) => {
        const { cover_photo, title } = tour;
        const image = cover_photo ? cover_photo : firstScene ? firstScene.image : null;

        if(image){
            const imageSrc = getImage(image, 'thumbnail');
            if (imageSrc) {
                return <img src={imageSrc} alt={title} />;
            }
        }

        return <img src={emptyCoverPhoto} className={classes.coverPhoto} alt={title} />;
    };

    const renderBarMenu = (id: string) => {
        return (
            <div>
                <IconButton
                    aria-controls={`menu-${id}`}
                    aria-haspopup="true"
                    onClick={event => handleMenu(event, id)}
                    className={[classes.icon, 'material-icons'].join(' ')}
                >
                    more_vert
                </IconButton>

                <Menu
                    id={`pic-${id}`}
                    anchorEl={anchorEl}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={menuOpen}
                    onClose={handleClose}
                >
                    <MenuItem onClick={onDeleteClick}>
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        Delete tour
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <ImageList gap={10} cols={getWidthCols()} rowHeight={240}>
                {tours.map((tour: Tour) => {
                    const { id, title, featured, photospheres } = tour;
                    if (!id) return null;

                    // const firstSceneID = photospheres?.find(scene => scene.image)?.id; // first scene that has an image
                    const firstScene = photospheres?.find(scene => scene.image); // first scene that has an image

                    // on Public list (not isOwner) count photospheres that have images
                    // isOwner is not correctly labeled has it only applies for the Public listing
                    const numberOfScenes = isOwner ? photospheres?.length : photospheres?.filter((obj) => obj.image).length ;

                    if (!firstScene && !isOwner) return null;

                    return (
                        <ImageListItem
                            key={`grid-tile-${id}`}
                            className={classes.listTile}
                            onClick={() => onTileClick(id, firstScene?.id || '')}
                            cols={featured ? 2 : 1}
                            rows={featured ? 2 : 1}
                        >
                            {renderImage(tour, firstScene)}

                            <ImageListItemBar
                                title={title}
                                subtitle={<span>{numberOfScenes} {numberOfScenes === 1 ? 'scene' : 'scenes'}</span>}
                                className={classes.titleBar}
                                actionIcon={displayMenu && renderBarMenu(id)}
                            />
                        </ImageListItem>
                    );
                })}
            </ImageList>
        </div>
    );
};

type GridProps = {
    tours: Tour[];
    onClick: Function;
    displayMenu?: boolean;
    onDeleteTour?: Function;
    isOwner?: boolean;
};

export default Grid;
