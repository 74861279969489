import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../Theme';

export const useStyles = makeStyles(theme => ({
    searchWrapper: {
        display: 'flex',
        margin: 20,
    },
    searchHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
    },
    selectMenu: {
        width: 200,
        borderRadius: 30,
        fontSize: 14,
        backgroundColor: colors.white,
        marginRight: theme.spacing(2),
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
    },
    selectHeight: {
        maxHeight: 250,
    },
    searchInput: {
        fontSize: 14,
    },
    openedInput: {
        width: 200,
    },
    iconButton: {
        padding: theme.spacing(2),
    },
    divider: {
        height: 28,
        margin: theme.spacing(1),
    },
    imageList: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 500,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    title: {
        // color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    infoText: {
        margin: theme.spacing(2),
        width: '100%',
        textAlign: 'left',
        color: grey[600],
        fontSize: 12,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minWidth: 500,
        minHeight: 380,

    },
    styleLinks: {
        paddingLeft: 8,
        paddingRight: 8,
        fontWeight: 600,
    },
    btnOK: {
        fontWeight: 600,
        position: "absolute",
        marginTop: 320,
        marginLeft: 630,
    }
}));
