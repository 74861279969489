import { Auth } from 'aws-amplify';

import { AsyncThunkAction } from '../../types/thunk';
import { userConstants } from './types';

const getCurrentUser = (): AsyncThunkAction => {
    return async dispatch => {
        try {
            const user = await Auth.currentAuthenticatedUser();

            user.isAdmin = user.signInUserSession?.idToken.payload['cognito:groups']?.includes('admin');

            dispatch({
                type: userConstants.SET_USER,
                payload: user,
            });
        } catch (error) {
            console.log(error);
            dispatch({ type: userConstants.LOGOUT });
        }
    };
};

const updateUser = (data: Object): AsyncThunkAction => {
    return async dispatch => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, data);

            dispatch({
                type: userConstants.SET_USER,
                payload: user,
            });
        } catch (error) {
            console.log(error);
        }
    };
};

export const getAuthOptions = async () => {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.getIdToken();

    return {
        headers: {
            Authorization: `Bearer ${authToken.getJwtToken()}`,
        },
    };
};

export const UserActions = {
    getCurrentUser,
    updateUser,
};
