const local = {
    apiUrl: 'https://dxh8bg1l92.execute-api.eu-west-2.amazonaws.com',
    awsConfig: {
        Auth: {
            oauth: {
                domain: 'expeditionspro.auth.eu-west-2.amazoncognito.com',
                scope: ['phone', 'email', 'profile', 'openid'],
                redirectSignIn: 'http://expeditions-pro-web-dev.s3-website.eu-west-2.amazonaws.com/',
                redirectSignOut: 'http://expeditions-pro-web-dev.s3-website.eu-west-2.amazonaws.com/',
                responseType: 'code',
            },
            region: 'eu-west-2',
            userPoolId: 'eu-west-2_TAmKBr4UF',
            userPoolWebClientId: '59bav7qphjh4lb4vhdd14u04t4',
        },
    },
    google: {
        baseUrl: 'https://maps.googleapis.com/maps/api',
        clientID: '1097587107326-ao51lfda89af3je5td3cuqb0r2j7qlvi.apps.googleusercontent.com',
        apiKey: 'AIzaSyA13-_aD2WQrUwd1zEkA8OWrF96EcepOCc',
        // Joe Rosa
        // clientID: '263342454856-g25alennpk4auengljp155upo82h23to.apps.googleusercontent.com',
        // apiKey: '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',
        // Matt DEV
        // clientID: '192441457071-b5iv487vhoc8g2o31ku4eue24va7vomb.apps.googleusercontent.com',
        // apiKey: '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',
    },
    stock: {
        cities360: {
            apiURL: 'https://www.360cities.net/api/images',
            apiKey: 'c2luZ2xldG9udGVjaDpjendSVHZWbWhGTlZZM1do'

        },
    },
    geocode: {
        apiURL: 'https://geocode.xyz',
        apiKey: '237305550962415483845x78132'
    },
    feedbackUrl: 'https://expeditionspro.com/feedback'
};

const devtours = {
    apiUrl: 'https://dxh8bg1l92.execute-api.eu-west-2.amazonaws.com',
    awsConfig: {
        Auth: {
            oauth: {
                domain: 'expeditionspro.auth.eu-west-2.amazoncognito.com',
                scope: ['phone', 'email', 'profile', 'openid'],
                redirectSignIn: 'http://devtours.expeditionspro.com/',
                redirectSignOut: 'http://devtours.expeditionspro.com/',
                // redirectSignIn: 'http://expeditions-pro-web-dev.s3-website.eu-west-2.amazonaws.com/',
                // redirectSignOut: 'http://expeditions-pro-web-dev.s3-website.eu-west-2.amazonaws.com/',
                responseType: 'code',
            },
            region: 'eu-west-2',
            userPoolId: 'eu-west-2_TAmKBr4UF',
            userPoolWebClientId: '59bav7qphjh4lb4vhdd14u04t4',
        },
    },
    google: {
        baseUrl: 'https://maps.googleapis.com/maps/api',
        clientID: '1097587107326-ao51lfda89af3je5td3cuqb0r2j7qlvi.apps.googleusercontent.com',
        apiKey: 'AIzaSyA13-_aD2WQrUwd1zEkA8OWrF96EcepOCc',
        // Joe Rosa
        // clientID: '263342454856-g25alennpk4auengljp155upo82h23to.apps.googleusercontent.com',
        // apiKey: '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',
        // Matt DEV
        // clientID: '192441457071-b5iv487vhoc8g2o31ku4eue24va7vomb.apps.googleusercontent.com',
        // apiKey: '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',
    },
    stock: {
        cities360: {
            apiURL: 'https://www.360cities.net/api/images',
            apiKey: 'c2luZ2xldG9udGVjaDpjendSVHZWbWhGTlZZM1do'
        },
    },
    geocode: {
        apiURL: 'https://geocode.xyz',
        apiKey: '237305550962415483845x78132'
    },
    feedbackUrl: 'https://expeditionspro.com/feedback'
};

const tours = {
    apiUrl: 'https://ipn24yk9zh.execute-api.eu-west-2.amazonaws.com',
    awsConfig: {
        Auth: {
            oauth: {
                domain: 'expeditionspro.auth.eu-west-2.amazoncognito.com',
                scope: ['phone', 'email', 'profile', 'openid'],
                redirectSignIn: 'http://tours.expeditionspro.com/',
                redirectSignOut: 'http://tours.expeditionspro.com/',
                // redirectSignIn: 'http://expeditions-pro-web.s3-website.eu-west-2.amazonaws.com/',
                // redirectSignOut: 'http://expeditions-pro-web.s3-website.eu-west-2.amazonaws.com/',
                responseType: 'code',
            },
            region: 'eu-west-2',
            userPoolId: 'eu-west-2_TAmKBr4UF',
            userPoolWebClientId: '59bav7qphjh4lb4vhdd14u04t4',
        },
    },
    google: {
        clientID: '322051864834-epqqmncjr6vkspap824ndkcimvevjaio.apps.googleusercontent.com',
        baseUrl: 'https://maps.googleapis.com/maps/api',
        apiKey: 'Z_OPlnaF-0ikj2yBGG4g2H5i',
    },
    stock: {
        cities360: {
            apiURL: 'https://www.360cities.net/api/images',
            apiKey: 'c2luZ2xldG9udGVjaDpjendSVHZWbWhGTlZZM1do'

        },
    },
    geocode: {
        apiURL: 'https://geocode.xyz',
        apiKey: '237305550962415483845x78132'
    },
    feedbackUrl: 'https://expeditionspro.com/feedback'
};

const config = process.env.REACT_APP_DEPLOY === 'tours' ? tours : process.env.REACT_APP_DEPLOY === 'devtours' ? devtours : local ;

// eslint-disable-next-line import/no-anonymous-default-export
export default { ...config };
