import { Photosphere } from '../../types/tour';
import { photosphereConstants } from '../actions/types';

export interface PhotosphereState {
    loading: boolean;
    photosphere?: Photosphere;
    list?: Photosphere[];
}

const defaultState: PhotosphereState = {
    loading: false,
    photosphere: undefined,
};

type Actions = keyof typeof photosphereConstants;
interface PhotosphereAction {
    type: Actions;
    payload: any;
}

export const photosphereReducer = (
    state: PhotosphereState = defaultState,
    action: PhotosphereAction
): PhotosphereState => {
    switch (action.type) {
        case photosphereConstants.LOADING_PHOTOSPHERES:
            return { ...state, loading: true };
        case photosphereConstants.CREATE_PHOTOSPHERE:
            return { ...state, photosphere: action.payload, loading: false };
        case photosphereConstants.FETCH_PHOTOSPHERES:
            return { ...state, list: action.payload, loading: false };
        case photosphereConstants.UPDATE_PHOTOSPHERE:
            return { ...state, photosphere: action.payload, loading: false };
        case photosphereConstants.FETCH_PHOTOSPHERE:
            return { ...state, photosphere: action.payload, loading: false };
        case photosphereConstants.DELETE_PHOTOSPHERE:
        case photosphereConstants.CLEAR_PHOTOSPHERE:
            return defaultState;
        default:
            return state;
    }
};
