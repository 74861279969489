import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';

import PrimaryButton from '../../components/button/Primary';
import UploadDropzone from '../../components/dropzone/Dropzone';

function AudioDialog({ audioType, onClose, onAddFile }: AudioDialogProps) {
    const [newAudioFile, setNewAudioFile] = useState<BinaryType>();

    const onAdd = () => {
        onAddFile(newAudioFile, audioType);
    };

    const onDropFile = (file: BinaryType) => {
        setNewAudioFile(file);
    };

    const text = {
        PS_AMBIENT_AUDIO: {
            title: 'Add ambient audio',
            description:
                'Sounds from the environment help make people feel like they’re really there',
        },
        PS_NARRATION_AUDIO: {
            title: 'Add scene narration',
            description: 'Narration will be played at the start of the scene',
        },
        HS_NARRATION_AUDIO: {
            title: 'Add narration',
            description: 'Narration will be played when this sight is selected',
        },
    };

    return (
        <Dialog
            open={true}
            maxWidth={'sm'}
            fullWidth={true}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{text[audioType].title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text[audioType].description}
                </DialogContentText>

                <UploadDropzone fileType={'audio'} onDropFile={onDropFile} />
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={() => onClose()}>Cancel</PrimaryButton>
                <PrimaryButton disabled={!newAudioFile} onClick={onAdd}>
                    Add
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}

type AudioDialogProps = {
    audioType: 'PS_AMBIENT_AUDIO' | 'PS_NARRATION_AUDIO' | 'HS_NARRATION_AUDIO';
    onClose: Function;
    onAddFile: Function;
};

export default AudioDialog;
