import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    dropzone: {
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '3px dashed ' + grey[300],
        backgroundColor: '#f2f2f2',
        color: grey[800],
    },
    defaultZone: {
        height: 240,
    },
    image360Zone: {
        height: 500,
    },
    audioZone: {
        height: 180,
    },
    tightText: {
        margin: theme.spacing(1),
    },
    centeredButton: {
        marginLeft: theme.spacing(3),
    },
    infoText: {
        margin: theme.spacing(2),
        width: '100%',
        textAlign: 'left',
        color: grey[600],
        fontSize: 12,
    },
}));
