import axios from 'axios';

import config from '../config/app';
import { getAuthOptions } from '../redux/actions/user';
import { PresignedData, UploadFileParams } from '../types/files';

const baseUrl = config.apiUrl;

export const uploadFileToS3 = async ({
    tourID,
    photosphereID,
    hotspotID,
    filename,
    file,
    media,
}: UploadFileParams) => {
    let url = `${baseUrl}/tour/private/${tourID}`;

    if (media === 'TR_COVER_IMAGE') {
        url += `/cover_photo_upload`;
    } else if (media === 'PS_SCENE_IMAGE') {
        url += `/photosphere/${photosphereID}/image_upload`;
    } else if (media === 'PS_AMBIENT_AUDIO') {
        url += `/photosphere/${photosphereID}/background_audio`;
    } else if (media === 'PS_NARRATION_AUDIO') {
        url += `/photosphere/${photosphereID}/narration_audio`;
    } else if (media === 'HS_OVERLAY_IMAGE') {
        url += `/photosphere/${photosphereID}/hotspot/${hotspotID}/image_upload`;
    } else if (media === 'HS_NARRATION_AUDIO') {
        url += `/photosphere/${photosphereID}/hotspot/${hotspotID}/narration_audio_upload`;
    }

    const presignedData = await createPresignedURL(url, filename);
    return await uploadFile(presignedData, file);
};

export const uploadTourZipToS3 = async (filename: string, file: BinaryType) => {
    const url = `${baseUrl}/tour_import`;

    const response = await createPresignedURL(url, filename);
    await uploadFile(response.s3_upload, file);

    return response.tour_import;
};

const createPresignedURL = async (url: string, filename: string) => {
    const options = await getAuthOptions();

    const response = await axios.post(url, { filename }, options);
    return response.data;
};

const uploadFile = async (presignedData: PresignedData, file: BinaryType) => {
    const authOptions = await getAuthOptions();
    const { fields, url } = presignedData;

    const options = {
        ...authOptions,
        ...{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    };

    const multiData = new FormData();

    for (const field in fields) {
        multiData.append(field, fields[field]);
    }

    multiData.append('file', file);

    await axios.post(url, multiData, options);
};
