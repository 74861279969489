/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Drawer,
    FormControl,
    IconButton,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {
    Add,
    Delete,
    RecordVoiceOver,
    VoiceOverOff,
    VolumeOff,
    VolumeUp,
} from '@material-ui/icons';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PrimaryButton from '../../components/button/Primary';
import RaisedButton from '../../components/button/Raised';
import AudioDialog from '../../components/dialog/AudioDialog';
import DeleteConfirmDialog from '../../components/dialog/DeleteConfirmDialog';
import SceneDialog from '../../components/dialog/SceneDialog';
import SingleGrid from '../../components/grid/SingleGrid';
import CounterText from '../../components/text/CounterText';
import { PhotosphereActions } from '../../redux/actions/photosphere';
import { TourActions } from '../../redux/actions/tour';
import { RootState } from '../../redux/store';
import { uploadFileToS3 } from '../../services/s3';
import { Media } from '../../types/files';
import { Photosphere } from '../../types/tour';
import { useStyles } from './Drawer.style';
import Hotspots from './Hotspots';

export interface DrawerSettings {
    title: string;
    location: string;
    description: string;
    credits: string;
}

function SceneDrawer({
    sceneID,
    photosphere,
    croppedImage,
    isPublic,
    isOwner,
    pitch,
    yaw,
    tour,
    loading,
    selectedHotspotID,
    onSelectHotspot,
    toggleSceneDialog,
    showSceneDialog,
    fetchPhotosphere,
    createPhotosphere,
    updatePhotosphere,
    deletePhotosphere,
    deletePhotosphereMedia,
    fetchTour,
}: DrawerProps) {
    const classes = useStyles();
    const history = useHistory();

    const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
    const [showAudioDialog, setShowAudioDialog] = useState(false);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [audioType, setAudioType] = useState<'PS_AMBIENT_AUDIO' | 'PS_NARRATION_AUDIO'>();

    const [submit, setSubmit] = useState(false);
    const [title, setTitle] = useState('Untitled scene');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [credits, setCredits] = useState('');

    const tourID = tour?.id;
    let photosphereID = photosphere?.id || '';

    useEffect(() => {
        if (tourID && sceneID) {
            fetchPhotosphere(tourID, sceneID, isPublic);
        }
    }, [sceneID]);

    useEffect(() => {
        populateDrawer();
    }, [photosphere]);


    const populateDrawer = () => {
        setTitle(photosphere?.title ?? 'Untitled scene');
        setLocation(photosphere?.location ?? '');
        setDescription(photosphere?.description ?? '');
        setCredits(photosphere?.credits ?? '');
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        if (name === 'scene-title') setTitle(value);
        else if (name === 'scene-location') setLocation(value);
        else if (name === 'scene-description') setDescription(value.replace(/<br\s*\/?>/mg,"\n"));
        else if (name === 'scene-credits') setCredits(value);
    };

    const isFormValid = () => {
        // return title && description;
        return title;
    };

    const onUpdateHotspots = async () => {
        if (!tourID || !photosphereID) return;

        await fetchTour(tourID, isPublic);
        await fetchPhotosphere(tourID, photosphereID, isPublic);
    };

    const onCreate = async () => {
        if (!tour || !tourID) return;

        return await createPhotosphere(tourID, {
            title,
            location,
            description,
            credits,
            pitch: 0,
            yaw: 0,
            order: (tour.photospheres?.length ?? 0) + 1,
            hotspots: [],
        });

    };

    const onUpdate = async (settings?: DrawerSettings) => {

        if (!tourID) return;
        if (!photosphereID) return;

        setSubmit(true);
        if (!isFormValid()) return;

        const attributes = (settings && settings.title) ? settings : {
            title,
            location,
            description,
            credits,
        }

        await updatePhotosphere(tourID, photosphereID, {
            ...attributes,
            pitch: photosphere?.pitch ?? 0,
            yaw: photosphere?.yaw ?? 0,
        });

        if(settings){
            await onPrepopulateDrawerSettings(attributes) }

        setTimeout(() => {
            fetchTour(tourID, isPublic);
            setSnackBarMessage('Scene updated successfully');
        }, 1000);
    };

    const onSetStarting = async () => {
        if (!tour || !tourID) return;
        if (!photosphere || !photosphereID) return;

        await updatePhotosphere(tourID, photosphereID, {
            title: photosphere.title,
            location: photosphere.location,
            description: photosphere.description,
            credits: photosphere.credits,
            pitch: pitch,
            yaw: yaw,
        });

        setSnackBarMessage('starting view updated successfully');
    };

    const onDeleteScene = () => {
        toggleDeleteConfirmDialog();
    };

    const toggleDeleteConfirmDialog = async (confirmDelete?: boolean) => {
        setShowDeleteConfirmDialog(!showDeleteConfirmDialog);

        if (confirmDelete) {
            if (!tourID || !photosphereID) return;
            await deletePhotosphere(tourID, photosphereID);
            history.push(`/tour/${tourID}`);
        }
    };

    const onRemoveMedia = async (media: Media) => {
        if (!tourID || !photosphereID) return;

        await deletePhotosphereMedia(tourID, photosphereID, media);
        await fetchPhotosphere(tourID, photosphereID, isPublic);
    };

    const updateNewScene = async () => {
        if (!tourID || !photosphereID) return;

        const scene = await fetchPhotosphere(tourID, photosphereID, false);

        if(scene.data.image.status === 'QUEUED'){
            setTimeout(() => {
                updateNewScene();
            }, 500);
        }
        else {
            if(scene.data.image.status !== 'SUCCESSFUL'){
                setSnackBarMessage(`Upload FAILED: ${scene.data.image.status}`);
                toggleSceneDialog();
            }
            else {
                await fetchTour(tourID, false);
                sceneID = photosphereID;
                history.push(`/tour/${tourID}/scene/${photosphereID}`);
                toggleSceneDialog(true);
                setSnackBarMessage('New Scene created successfully');
            }
        }
    }

    const onUploadSceneFile = async (file: BinaryType, settings?: DrawerSettings) => {
        if (!tourID) return;

        if (!photosphereID) {
            photosphereID = await onCreate();
            setSnackBarMessage('Uploading New Scene');
            await uploadFile(file, 'PS_SCENE_IMAGE', true);
        } else {
            setSnackBarMessage('Uploading New Scene');
            await uploadFile(file, 'PS_SCENE_IMAGE');
        }
        await updateNewScene();

        if(settings && settings.title) {
            onUpdate(settings)
            setTimeout(() => {
                onUpdate(settings)
            }, 500);
            
        }
    };

    const onPrepopulateDrawerSettings = async (settings: DrawerSettings) => {
        setTitle(settings.title);
        setLocation(settings.location);
        setDescription(settings.description);
        setCredits(settings.credits);
    };

    const onUploadAudioFile = async (file: BinaryType, media: Media) => {
        if (!tourID || !photosphereID) return;

        await uploadFile(file, media);

        toggleAudioDialog();
    };

    const uploadFile = async (file: BinaryType, media: Media, redirect?: boolean) => {
        if (!tourID || !photosphereID) return;

        const extension = media === 'PS_SCENE_IMAGE' ? 'png' : 'mp3';
        const filename = `tour_${tourID}_scene_${photosphereID}_${media}.${extension}`;

        await uploadFileToS3({ tourID, photosphereID, media, filename, file });

        if (redirect) {
            await fetchTour(tourID, false);
            history.push(`/tour/${tourID}/scene/${photosphereID}`);
            return;
        }

        if (media === 'PS_SCENE_IMAGE') {
            await fetchTour(tourID, isPublic);
        }

        await fetchPhotosphere(tourID, photosphereID, isPublic);

    };

    const toggleAudioDialog = (type?: 'PS_AMBIENT_AUDIO' | 'PS_NARRATION_AUDIO') => {
        if (type) setAudioType(type);
        setShowAudioDialog(!showAudioDialog);
    };

    const renderAudio = () => {
        const ambience = photosphere?.background_audio;
        const narration = photosphere?.narration_audio;

        return (
            <div>
                {ambience && (
                    <div className={classes.audioSpacing}>
                        <Typography className={classes.audioSpacing} variant="subtitle2">
                            Scene ambient audio
                        </Typography>
                        <audio src={ambience} controls controlsList={'nodownload'} />
                    </div>
                )}
                {narration && (
                    <div>
                        <Typography className={classes.audioSpacing} variant="subtitle2">
                            Scene narration
                        </Typography>
                        <audio src={narration} controls controlsList={'nodownload'} />
                    </div>
                )}
            </div>
        );
    };

    const renderAudioControls = () => {
        const ambience = photosphere?.background_audio;
        const narration = photosphere?.narration_audio;

        return (
            <div className={classes.audioActionsContainer}>
                <Tooltip title={(ambience ? 'Remove' : 'Add') + ' ambient audio'}>
                    <span>
                        <IconButton
                            aria-label={`info about`}
                            className={'material-icons'}
                            disabled={!isOwner}
                            onClick={() =>
                                ambience
                                    ? onRemoveMedia('PS_AMBIENT_AUDIO')
                                    : toggleAudioDialog('PS_AMBIENT_AUDIO')
                            }
                        >
                            {ambience ? <VolumeOff /> : <VolumeUp />}
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title={(narration ? 'Remove' : 'Add') + ' scene narration'}>
                    <span>
                        <IconButton
                            aria-label={`info about`}
                            className={'material-icons'}
                            disabled={!isOwner}
                            onClick={() =>
                                narration
                                    ? onRemoveMedia('PS_NARRATION_AUDIO')
                                    : toggleAudioDialog('PS_NARRATION_AUDIO')
                            }
                        >
                            {narration ? <VoiceOverOff /> : <RecordVoiceOver />}
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        );
    };

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={true}
            classes={{ paper: classes.drawerPaper }}
        >
            <SingleGrid
                onClick={isOwner ? toggleSceneDialog : undefined }
                photosphere={photosphere}
                croppedImage={croppedImage}
            />

            <FormControl className={classes.formWrapper}>
                <div className={classes.titleContainer}>
                    {isOwner && (
                        <div className={classes.startingButton}>
                            <RaisedButton onClick={onSetStarting} startIcon={<Add />}>
                                Set Starting View
                            </RaisedButton>
                        </div>
                    )}

                    <TextField
                        id="scene-title"
                        name="scene-title"
                        value={title}
                        onChange={handleChange}
                        error={submit && !title}
                        color="primary"
                        multiline={true}
                        disabled={!isOwner}
                        inputProps={{
                            maxLength: 50,
                        }}
                        InputProps={{
                            classes: {
                                input: classes.infoTitle,
                            },
                        }}
                    />

                    { isOwner && <CounterText text={title} max={50} /> }

                    <div>
                    { isOwner && 
                        <Tooltip title={'Delete Scene'}>
                            <span>
                                <IconButton
                                    aria-label={`info about`}
                                    className={'material-icons'}
                                    disabled={!isOwner}
                                    onClick={onDeleteScene}
                                >
                                    <Delete fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    </div>
                </div>

                <TextField
                    id="scene-location"
                    name="scene-location"
                    value={location}
                    onChange={handleChange}
                    className={classes.formSpacing}
                    label="Location"
                    color="primary"
                    fullWidth={true}
                    disabled={!isOwner}
                />

                <TextField
                    id="scene-description"
                    name="scene-description"
                    value={description}
                    onChange={handleChange}
                    error={submit && !description}
                    label="Description"
                    color="primary"
                    fullWidth={true}
                    multiline={true}
                    disabled={!isOwner}
                    maxRows={10}
                    inputProps={{
                        fontSize: 8,
                    }}
                />

                { isOwner && <CounterText text={description} /> }

                <TextField
                    id="scene-credits"
                    name="scene-credits"
                    value={credits}
                    onChange={handleChange}
                    className={classes.formSpacing}
                    label="Credits"
                    color="primary"
                    fullWidth={true}
                    disabled={!isOwner}
                />

                {renderAudio()}

                <Box className={classes.actionContainer}>
                    {renderAudioControls()}

                    {isOwner && (
                        <PrimaryButton loading={loading} onClick={onUpdate}>
                            Update
                        </PrimaryButton>
                    )}
                </Box>
            </FormControl>

            <Hotspots
                hotspots={photosphere?.hotspots}
                onUpdate={onUpdateHotspots}
                selectedHotspotID={selectedHotspotID}
                onSelectHotspot={onSelectHotspot}
                isPublic={!isOwner}
                pitch={pitch}
                yaw={yaw}
            />

            {showAudioDialog && audioType && (
                <AudioDialog
                    audioType={audioType}
                    onAddFile={onUploadAudioFile}
                    onClose={toggleAudioDialog}
                />
            )}

            {showSceneDialog && (
                <SceneDialog onClose={toggleSceneDialog} onAddFile={onUploadSceneFile} onPrepopulate={onPrepopulateDrawerSettings}/>
            )}

            {showDeleteConfirmDialog && (
                <DeleteConfirmDialog item="scene" onClose={toggleDeleteConfirmDialog} />
            )}

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={!!snackBarMessage}
                autoHideDuration={5000}
                onClose={() => setSnackBarMessage(null)}
                message={snackBarMessage}
            />
        </Drawer>
    );
}

const mapState = (state: RootState) => {
    return {
        user: state.user.user,
        tour: state.tour.tour,
        loading: state.tour.loading || state.photosphere.loading,
        photosphere: state.photosphere.photosphere,
    };
};

const mapDispatch = {
    fetchTour: (tourID: string, isPublic: boolean) => TourActions.fetchTour(tourID, isPublic),
    fetchPhotosphere: (tourID: string, photosphereID: string, isPublic: boolean) =>
        PhotosphereActions.fetchPhotosphere(tourID, photosphereID, isPublic),
    createPhotosphere: (tourID: string, data: Photosphere) =>
        PhotosphereActions.createPhotosphere(tourID, data),
    updatePhotosphere: (tourID: string, photosphereID: string, data: Photosphere) =>
        PhotosphereActions.updatePhotosphere(tourID, photosphereID, data),
    deletePhotosphere: (tourID: string, photosphereID: string) =>
        PhotosphereActions.deletePhotosphere(tourID, photosphereID),
    deletePhotosphereMedia: (tourID: string, photosphereID: string, media: Media) =>
        PhotosphereActions.deletePhotosphereMedia(tourID, photosphereID, media),
};

const connector = connect(mapState, mapDispatch);
type DrawerProps = ConnectedProps<typeof connector> & {
    sceneID?: string;
    selectedHotspotID?: string;
    onSelectHotspot: Function;
    toggleSceneDialog: Function;
    showSceneDialog: boolean;
    croppedImage?: string;
    isPublic: boolean;
    isOwner: boolean;
    pitch: number;
    yaw: number;
};

export default connector(SceneDrawer);
