import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    pageGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    actionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px 20px',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(8),
    },
    subtitleContainer: {
        paddingBottom: theme.spacing(4),
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
    },
    finishedContainer: {
        display: 'flex',
    },
    finishedIcon: {
        marginRight: theme.spacing(1),
    },
}));
