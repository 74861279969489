import { ImageData } from '../types/tour';

export const sortArray = <T,>(arr: T[] | any[], sortKey: string): T[] => {
    return arr.sort((a, b) => {
        if (a[sortKey] < b[sortKey]) return -1;
        if (a[sortKey] > b[sortKey]) return 1;
        return 0;
    });
};

/**
 * This will invert the yaw value and reposition it 90°
 */
export const invertYawPosition = (number: number) => {
    if (!number) return;

    const inverted = number < 0 ? Math.abs(number) : -Math.abs(number);
    return inverted + 90;
};

type ImageSize = 'original' | 'medium' | 'thumbnail';

/**
 * Get the image URL based on preferred size
 */
export const getImage = (imageData?: ImageData, size?: ImageSize) => {
    if (!imageData) return;

    const { original_raw, medium_raw, thumbnail_raw } = imageData;

    if (size === 'original') return `/${ original_raw || medium_raw || thumbnail_raw }`;
    if (size === 'thumbnail') return `/${ thumbnail_raw || medium_raw || original_raw }`;
    return `/${ medium_raw || original_raw || thumbnail_raw }`;
};
