import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#FFF',
        width: '100%',
        height: 100,
        overflowX: 'auto',
    },
    selectedScene: {
        borderTop: `3px solid ${theme.palette.primary.main}`,
        borderRadius: 0,
        color: grey[700],
    },
    unselectedScene: {
        color: grey[700],
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'row',
        marginLeft: theme.spacing(1),
    },
}));
