export const userConstants = {
    SET_USER: 'SET_USER',
    LOGOUT: 'LOGOUT',
    SET_AUTH_STATE: 'SET_AUTH_STATE',
};

export const tourConstants = {
    LOADING_TOURS: 'LOADING_TOURS',
    FETCH_TOURS: 'FETCH_TOURS',
    FETCH_PUBLIC_TOURS: 'FETCH_PUBLIC_TOURS',
    CREATE_TOUR: 'CREATE_TOUR',
    FETCH_TOUR: 'FETCH_TOUR',
    UPDATE_TOUR: 'UPDATE_TOUR',
    DELETE_TOUR: 'DELETE_TOUR',
};

export const photosphereConstants = {
    LOADING_PHOTOSPHERES: 'LOADING_PHOTOSPHERES',
    FETCH_PHOTOSPHERES: 'FETCH_PHOTOSPHERES',
    CREATE_PHOTOSPHERE: 'CREATE_PHOTOSPHERE',
    FETCH_PHOTOSPHERE: 'FETCH_PHOTOSPHERE',
    UPDATE_PHOTOSPHERE: 'UPDATE_PHOTOSPHERE',
    DELETE_PHOTOSPHERE: 'DELETE_PHOTOSPHERE',
    CLEAR_PHOTOSPHERE: 'CLEAR_PHOTOSPHERE',
};

export const hotspotConstants = {
    LOADING_HOTSPOTS: 'LOADING_HOTSPOTS',
    FETCH_HOTSPOTS: 'FETCH_HOTSPOTS',
    CREATE_HOTSPOT: 'CREATE_HOTSPOT',
    UPDATE_HOTSPOT: 'UPDATE_HOTSPOT',
    DELETE_HOTSPOT: 'DELETE_HOTSPOT',
};

export const tourImportConstants = {
    LOADING_IMPORTS: 'LOADING_IMPORTS',
    FETCH_IMPORTS: 'FETCH_IMPORTS',
    FETCH_IMPORT: 'FETCH_IMPORT',
};

export const categoryConstants = {
    LOADING_CATEGORIES: 'LOADING_CATEGORIES',
    FETCH_CATEGORIES: 'FETCH_CATEGORIES',
};
