import { ImageList, ImageListItem, ImageListItemBar, IconButton } from '@material-ui/core';
import React from 'react';

import emptyPhotosphere from '../../assets/images/blackBox.jpg';
import { getImage } from '../../services/utils';
import { Photosphere } from '../../types/tour';
import { useStyles } from './SingleGrid.style';

const SingleGrid = ({ onClick, photosphere, croppedImage }: SingleGridProps) => {
    const classes = useStyles();

    const title = photosphere?.title;
    const photosphereImage = getImage(photosphere?.image);
    const imageSrc = croppedImage ?? photosphereImage ?? emptyPhotosphere;

    const onGridClick = () => {
        if (onClick) onClick();
    };

    return (
        <div>
            <ImageList cols={1} rowHeight={160}>
                <ImageListItem className={classes.listTile} onClick={onGridClick}>
                    <img src={imageSrc} alt={title} />

                    <ImageListItemBar
                        title={'Update Image'}
                        className={classes.titleBar}
                        actionIcon={
                            <IconButton className={[classes.icon, 'material-icons'].join(' ')}>
                                {photosphere && onClick && 360}
                            </IconButton>
                        }
                    />
                </ImageListItem>
            </ImageList>
        </div>
    );
};

type SingleGridProps = {
    photosphere?: Photosphere;
    onClick?: Function;
    croppedImage?: string;
};

export default SingleGrid;
