export const getCroppedImage = async (imageSrc: string): Promise<string | undefined> => {
    try {
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        const { height, width } = image;

        canvas.width = width;
        canvas.height = height / 2;

        ctx.drawImage(image, 0, 0);

        return new Promise(resolve => {
            canvas.toBlob(file => {
                resolve(URL.createObjectURL(file!));
            }, 'image/jpeg');
        });
    } catch (error) {
        console.error('An error occurred cropping image', error);
    }
};

const createImage = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
        image.src = url;
    });
};
