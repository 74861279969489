import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../Theme';

export const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(3),
        fontWeight: 500,
        color: colors.orange,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: colors.greyLight,
        },
    },
    scene: {
        margin: theme.spacing(0),
        width: 200,
        height: '100%',
    },
    raised: {
        height: 50,
        borderRadius: 30,
        backgroundColor: colors.white,
    },
}));
