import { Category } from '../../types/tour';
import { categoryConstants } from '../actions/types';

export interface CategoryState {
    loading: boolean;
    category?: Category;
    list?: Category[];
}

const defaultState: CategoryState = {
    loading: false,
    category: undefined,
};

type Actions = keyof typeof categoryConstants;
interface CategoryAction {
    type: Actions;
    payload: any;
}

export const categoryReducer = (
    state: CategoryState = defaultState,
    action: CategoryAction
): CategoryState => {
    switch (action.type) {
        case categoryConstants.LOADING_CATEGORIES:
            return { ...state, loading: true };
        case categoryConstants.FETCH_CATEGORIES:
            return { list: action.payload, loading: false };
        default:
            return state;
    }
};
