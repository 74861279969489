/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    FormControl,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Snackbar,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PrimaryButton from '../../components/button/Primary';
import AbandonDialog from '../../components/dialog/AbandonDialog';
import DeleteConfirmDialog from '../../components/dialog/DeleteConfirmDialog';
import UploadDropzone from '../../components/dropzone/Dropzone';
import Header from '../../components/navigation/Header';
import CounterText from '../../components/text/CounterText';
import { CategoryActions } from '../../redux/actions/category';
import { TourActions } from '../../redux/actions/tour';
import { RootState } from '../../redux/store';
import { uploadFileToS3 } from '../../services/s3';
import { getImage } from '../../services/utils';
import { Photosphere, Tour } from '../../types/tour';
import SceneSelector from '../scene/SceneSelector';
import { useStyles } from './Tour.style';

function TourScreen({
    tour,
    loading,
    categories,
    createTour,
    fetchTour,
    updateTour,
    deleteTour,
    fetchCategories,
    user,
}: TourProps) {
    const classes = useStyles();
    const history = useHistory();

    const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
    const [showAbandonDialog, setShowAbandonDialog] = useState(false);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [newCoverImage, setNewCoverImage] = useState<BinaryType>();

    const [title, setTitle] = useState('Untitled tour');
    const [description, setDescription] = useState('');
    const [categoryID, setCategoryID] = useState<string>('');
    const [photospheres, setPhotospheres] = useState<Photosphere[]>([]);
    const [isPublic, setIsPublic] = useState(false);

    const params: { tourID?: string } = useParams();
    let tourID = params?.tourID;

    const isOwner = user?.isAdmin ? true : tourID ? (tour?.user_id === user?.attributes?.sub) : true;

    useEffect(() => {
        fetchCategories();
        if (tourID) fetchTour(tourID, isPublic);
    }, []);

    useEffect(() => {
        if (tour) populateTour();
    }, [tour, loading]);

    const populateTour = () => {
        if (!tour || tourID !== tour.id) return;

        setTitle(tour.title);
        setDescription(tour.description);
        setCategoryID(tour.category_id ?? '');
        setPhotospheres(tour.photospheres ?? []);
        setIsPublic(tour.is_public);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        if (name === 'info-title') setTitle(value);
        else if (name === 'info-description') setDescription(value);
    };

    const handleSelect = (event: ChangeEvent<any>) => {
        const { name, value } = event.target;
        if (name === 'info-category') setCategoryID(value);
    };

    const tourHasAmendments = () => {
        if (title !== tour?.title) return true;
        if (description !== tour?.description) return true;
        // if (!!categoryID && categoryID !== tour?.category_id) return true;
        // if (newCoverImage) return true;
        return false;
    };

    const isFormValid = () => {
        if (!title || !description || !categoryID) return false;
        return true;
    };

    const onSubmit = async () => {
        setSubmit(true);
        if (!isFormValid()) return;

        if (tour?.id) {
            await updateTour(tour.id, {
                title,
                description,
                category_id: categoryID || undefined,
                is_public: isPublic,
            });

            // history.push(location.pathname);

            setSnackBarMessage('Tour updated successfully');
        } else {
            tour = await createTour({
                title,
                description,
                category_id: categoryID || undefined,
                is_public: false,
            });

            tourID = tour?.id; // set new Tour ID
            window.history.replaceState(null, "ExpeditionsPro", `/tour/${tourID}`); // update URL (deeplink)
            setSnackBarMessage('New tour created successfully');
        }

        if (newCoverImage) {
            await uploadFile(newCoverImage);
        }
    };

    const onDropFile = async (file: BinaryType) => {
        if (!tourID) setNewCoverImage(file);
        else await uploadFile(file);
    };

    const uploadFile = async (file: BinaryType) => {
        if (!tourID) return;
        const filename = `tour_${tourID}_cover_photo.jpg`;

        await uploadFileToS3({
            tourID,
            media: 'TR_COVER_IMAGE',
            filename,
            file,
        });

        setNewCoverImage(undefined);
    };

    const navigateBack = () => {
        history.push(isPublic ? '/tours' : '/');
    };

    const onDeleteTour = async () => {
        toggleDeleteConfirmDialog();
    };

    const onAbandon = () => {
        if (tourHasAmendments()) toggleAbandonDialog();
        else navigateBack();
    };

    const toggleAbandonDialog = (goBack?: boolean) => {
        setShowAbandonDialog(!showAbandonDialog);
        if (goBack) navigateBack();
    };

    const toggleDeleteConfirmDialog = async (confirmDelete?: boolean) => {
        setShowDeleteConfirmDialog(!showDeleteConfirmDialog);

        if (confirmDelete) {
            if (!tour?.id) return;
            await deleteTour(tour.id);

            navigateBack();
        }
    };

    const renderActionButtons = () => {
        return (
            <Box className={classes.actionContainer}>
                <PrimaryButton onClick={onAbandon}>Cancel</PrimaryButton>
                {tour && <PrimaryButton onClick={onDeleteTour}>Delete</PrimaryButton>}
                <PrimaryButton loading={loading} onClick={onSubmit}>
                    {tour ? 'Save' : 'Create'}
                </PrimaryButton>
            </Box>
        );
    };

    return (
        <div>
            <div className={classes.centeredPage}>
                <Header />
                <div className={classes.infoPageWrapper}>
                    <div className={classes.dropzoneContainer}>
                        <UploadDropzone
                            isPublic={!isOwner}
                            fileType={'coverImage'}
                            onDropFile={onDropFile}
                            currentFile={getImage(tour?.cover_photo)}
                        />
                        { tour && isOwner &&
                            <Typography variant="subtitle2" className={classes.publicLinkOuter}>
                                Public Link:
                                <Link
                                    href={window.location.href}
                                    color="primary"
                                    underline="none"
                                    className={classes.publicLinkInner}
                                >
                                    {window.location.href}
                                </Link>
                            </Typography>
                        }
                    </div>

                    <FormControl className={classes.formWrapper}>
                        <TextField
                            id="info-title"
                            name="info-title"
                            value={title}
                            onChange={handleChange}
                            error={submit && !title}
                            label="Title"
                            color="primary"
                            fullWidth={true}
                            multiline={true}
                            disabled={!isOwner}
                            inputProps={{
                                maxLength: 50,
                            }}
                            InputProps={{
                                classes: {
                                    input: classes.infoTitle,
                                },
                            }}
                        />

                        <CounterText text={title} max={50} />

                        <TextField
                            id="info-description"
                            name="info-description"
                            value={description}
                            onChange={handleChange}
                            error={submit && !description}
                            className={classes.formSpacing}
                            placeholder="Description"
                            minRows={10}
                            maxRows={10}
                            color="primary"
                            multiline={true}
                            variant="outlined"
                            disabled={!isOwner}
                        />

                        <FormControl>
                            <InputLabel id="info-category-label">Category</InputLabel>
                            <Select
                                id="info-category"
                                name="info-category"
                                labelId="info-category-label"
                                value={categoryID}
                                onChange={handleSelect}
                                error={submit && !categoryID}
                                className={classes.formSpacing}
                                color="primary"
                                disabled={!isOwner}
                                MenuProps={{ classes: { paper: classes.selectMenu } }}
                            >
                                {categories?.length &&
                                    categories.map(cat => (
                                        <MenuItem key={cat.id} value={cat.id}>
                                            {cat.value}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>

                        {isOwner && renderActionButtons()}

                        { tour && user?.isAdmin &&
                            <React.Fragment>
                                <Typography variant="subtitle1">
                                    <Switch
                                        color="primary"
                                        inputProps={ { 'aria-label': 'Switch demo' } }
                                        checked={isPublic}
                                        onChange={() => setIsPublic(!isPublic)}/>
                                    { isPublic ? 'Listed' : 'Unlisted' }
                                </Typography>
                            </React.Fragment>
                        }

                        {/* Toggle for user :: to enable in the next iteraction   */}
                        {/* { tour && isOwner && !user?.isAdmin && 
                            <React.Fragment>
                                <Typography variant="subtitle1">
                                    <Switch
                                        color="primary"
                                        inputProps={ { 'aria-label': 'Switch demo' } }
                                        checked={isPublic}
                                        onChange={() => setIsPublic(!isPublic)}/>
                                    { isPublic ? 'Listed' : 'Unlisted' }
                                </Typography>
                            </React.Fragment>
                        } */}

                    </FormControl>

                    {showAbandonDialog && <AbandonDialog onClose={toggleAbandonDialog} />}

                    {showDeleteConfirmDialog && (
                        <DeleteConfirmDialog item="tour" onClose={toggleDeleteConfirmDialog} />
                    )}

                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        open={!!snackBarMessage}
                        autoHideDuration={5000}
                        onClose={() => setSnackBarMessage(null)}
                        message={snackBarMessage}
                    />
                </div>

                <SceneSelector page={'tour'} photospheres={photospheres} />
            </div>
        </div>
    );
}

const mapState = (state: RootState) => {
    return {
        user: state.user.user,
        loading: state.tour.loading,
        tour: state.tour.tour,
        categories: state.category.list,
    };
};

const mapDispatch = {
    createTour: (tour: Tour) => TourActions.createTour(tour),
    fetchTour: (tourID: string, isPublic: boolean) => TourActions.fetchTour(tourID, isPublic),
    updateTour: (tourID: string, data: Tour) => TourActions.updateTour(tourID, data),
    deleteTour: (tourID: string) => TourActions.deleteTour(tourID),
    fetchCategories: () => CategoryActions.fetchCategories(),
};

const connector = connect(mapState, mapDispatch);
type TourProps = ConnectedProps<typeof connector>;

export default connector(TourScreen);
