import { Tour } from '../../types/tour';
import { tourConstants } from '../actions/types';

export interface TourState {
    loading: boolean;
    tour?: Tour;
    list?: Tour[];
    public?: Tour[];
}

const defaultState: TourState = {
    loading: false,
    tour: undefined,
};

type Actions = keyof typeof tourConstants;
interface TourAction {
    type: Actions;
    payload: any;
}

export const tourReducer = (state: TourState = defaultState, action: TourAction): TourState => {
    switch (action.type) {
        case tourConstants.LOADING_TOURS:
            return { ...state, loading: true };
        case tourConstants.FETCH_TOURS:
            return { list: action.payload, loading: false };
        case tourConstants.FETCH_PUBLIC_TOURS:
            return { public: action.payload, loading: false };
        case tourConstants.FETCH_TOUR:
        case tourConstants.CREATE_TOUR:
        case tourConstants.UPDATE_TOUR:
            return { tour: action.payload, loading: false };
        case tourConstants.DELETE_TOUR:
            return { ...state, loading: false };
        default:
            return state;
    }
};
