import React from 'react';

import { useStyles } from './CounterText.style';

const CounterText = ({ text, max }: { text?: string; max?: number }) => {
    const classes = useStyles();

    return (
        <p className={classes.counter}>
            { text && text.length}{ max && '/'+max}
        </p>
    );
};

export default CounterText;
