import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    AppBar,
    Divider,
    IconButton,
    Hidden,
    Link,
    List,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
    Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { AccountCircle, ChevronRight } from '@material-ui/icons';

import logoTitle from '../../assets/logos/logo-text.png';
import logoImage from '../../assets/logos/logo.png';
import { UserActions } from '../../redux/actions/user';
import { RootState } from '../../redux/store';
import { useStyles } from './Header.style';

import config from '../../config/app';

const navigationLinks = [
    { name: "Home", href: "https://expeditionspro.com" },
    { name: "Browse", href: "/tours" },                     // (previously /public)
    { name: "My Tours", href: "/mytours" },                 // (previously /)
    { name: "Contact", href: config.feedbackUrl },
];

const Header = ({ position = 'sticky', getCurrentUser }: HeaderProps) => {
    const classes = useStyles();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuMobileOpen, setMenuMobileOpen] = useState<boolean>(false);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onNavigateHome = () => {
        history.push('/');
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onLogout = async () => {
        await Auth.signOut();
        await getCurrentUser();
    };

    return (
        <div className={classes.root}>
            <AppBar color={'inherit'} position={position}>
                <Toolbar className={classes.homeToolbar}>
                    <IconButton edge="start" onClick={onNavigateHome} aria-label="menu">
                        <img className={classes.logo} alt="logo" src={logoImage} />
                        <img height={30} alt="logo title" src={logoTitle} />
                    </IconButton>

                    <Hidden smDown>
                        <div>
                            { navigationLinks.map( (item, idx) => 
                                <Link
                                    key={idx}
                                    href={item.href}
                                    color="secondary"
                                    underline="none"
                                    className={classes.styleLinks}
                                >
                                    {item.name}
                                </Link>
                            )}
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="primary"
                            >
                                <AccountCircle className={classes.icon} />
                            </IconButton>

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={onClose}
                            >
                                <MenuItem onClick={onLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    </Hidden>

                    <Hidden mdUp>
                        <IconButton
                            aria-label="menu mobile devices"
                            aria-haspopup="true"
                            onClick={() => setMenuMobileOpen(true)}
                            color="primary"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>

                </Toolbar>

            </AppBar>

            {position === 'fixed' && <div className={classes.fixedSpacing}></div>}

            <SwipeableDrawer
                open={menuMobileOpen}
                anchor="right"
                onOpen={() => setMenuMobileOpen(true)}
                onClose={() => setMenuMobileOpen(false)}
            >
                <div>
                    <IconButton>
                        <ChevronRight onClick={() => setMenuMobileOpen(false)} />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    { navigationLinks.map( (item, idx) => 
                        <ListItem key={idx}>
                            <Link
                                href={item.href}
                                color="textPrimary"
                                underline="none"
                            >
                                {item.name}
                            </Link>
                        </ListItem>
                    )}
                    <ListItem onClick={onLogout}>
                        Logout
                    </ListItem>
                </List>
            </SwipeableDrawer>
        </div>
    );
};

const mapState = (state: RootState) => {
    return {
        user: state.user.user,
    };
};

const mapDispatch = {
    getCurrentUser: () => UserActions.getCurrentUser(),
};

const connector = connect(mapState, mapDispatch);
type HeaderProps = ConnectedProps<typeof connector> & {
    position?: 'static' | 'fixed' | 'sticky';
};

export default connector(Header);
