import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    centeredPage: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    infoPageWrapper: {
        display: 'flex',
        justifyContent: 'row',
        [theme.breakpoints.down('md')]: { // eslint-disable-line no-useless-computed-key
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    formWrapper: {
        margin: theme.spacing(3),
        minWidth: 460,
    },
    formSpacing: {
        marginBottom: theme.spacing(4),
    },
    infoTitle: {
        fontSize: 40,
        lineHeight: 1.4,
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    sceneButton: {
        '&:hover': {
            backgroundColor: '#000',
        },
        width: 200,
    },
    selectMenu: {
        maxHeight: 250,
    },
    dropzoneContainer: {
        margin: theme.spacing(3),
        width: 460,
    },
    publicLinkOuter: {
        paddingLeft: 10,
    },
    publicLinkInner: {
        paddingLeft: 10,
        fontWeight: 600,
    }
}));
