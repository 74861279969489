/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Container, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Done } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '../../components/grid/Grid';
import Header from '../../components/navigation/Header';
import GlobalSearch from '../../components/search/GlobalSearch';
import { TourActions } from '../../redux/actions/tour';
import { RootState } from '../../redux/store';
import { FetchToursParams, Tour } from '../../types/tour';
import { useStyles } from './Public.style';

function Public({ tours, loading, fetchTours }: PublicProps) {
    const classes = useStyles();
    const history = useHistory();

    const [allTours, setAllTours] = useState<Tour[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [fireReady, setFireReady] = useState(false);
    const [noMoreTours, setNoMoreTours] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryID, setCategoryID] = useState('');

    useEffect(() => {
        fetchMoreTours();
    }, []);

    useEffect(() => {
        if (fireReady) fetchMoreTours();
    }, [fireReady]);

    useEffect(() => {
        if (tours?.length) {
            setAllTours([...allTours, ...tours]);
        } else if (tours?.length === 0) {
            setNoMoreTours(true);
        }
    }, [tours]);

    useEffect(() => {
        const computeProgress = () => {
            if (loading || noMoreTours) return;

            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

            const scrollLength = scrollHeight - clientHeight;
            const progress = (100 * scrollTop) / scrollLength;

            if (progress > 70) setFireReady(true);
        };

        // Adding event listener on mounting
        window.addEventListener('scroll', computeProgress);

        return () => window.removeEventListener('scroll', computeProgress);
    });

    const fetchMoreTours = async () => {
        const nextPage = currentPage + 1;
        await fetchTours({
            page: nextPage,
            size: 15,
            search: searchTerm || undefined,
            category_id: categoryID || undefined,
            isPublic: true,
        });

        setCurrentPage(nextPage);
        setFireReady(false);
    };

    const onSearchTour = (value: string, selectedCategoryID: string) => {
        setSearchTerm(value);
        setCategoryID(selectedCategoryID);

        setCurrentPage(0);
        setAllTours([]);
        setNoMoreTours(false);
        setFireReady(true);
    };

    const handleTourOpen = (tourID: string, firstSceneID: string) => {
        history.push(`/tour/${tourID}/scene/${firstSceneID}`);
    };

    const getMaxWidth = () => {
        const small = useMediaQuery('(min-width:600px)');
        const medium = useMediaQuery('(min-width:960px)');
        const large = useMediaQuery('(min-width:1280px)');
        if (large) return 'lg';
        if (medium) return 'md';
        if (small) return 'sm';
        return 'lg';
    };

    const renderLoadingResult = () => {
        if (loading) {
            return <CircularProgress />;
        }

        if (noMoreTours) {
            return (
                <Box className={classes.finishedContainer}>
                    <Done color="primary" className={classes.finishedIcon} />
                    <Typography color="textSecondary">Finished loading tours</Typography>
                </Box>
            );
        }
    };

    return (
        <main>
            <Header position={'fixed'} />
            <Container className={classes.pageGrid} maxWidth={getMaxWidth()}>
                <Box className={classes.actionContainer}>
                    <div className={classes.subtitleContainer}>
                        <Typography variant="subtitle1">Browse all public tours</Typography>
                    </div>

                    <GlobalSearch onSearch={onSearchTour} />
                </Box>

                <Grid tours={allTours} onClick={handleTourOpen} />

                <Box className={classes.loadingContainer}>{renderLoadingResult()}</Box>
            </Container>
        </main>
    );
}

const mapState = (state: RootState) => {
    return {
        loading: state.tour.loading,
        tours: state.tour.public,
    };
};

const mapDispatch = {
    fetchTours: (fetchParams: FetchToursParams) => TourActions.fetchTours(fetchParams),
    deleteTour: (tourID: string) => TourActions.deleteTour(tourID),
};

const connector = connect(mapState, mapDispatch);
type PublicProps = ConnectedProps<typeof connector>;

export default connector(Public);
