import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../Theme';

const mediaQuery = window.matchMedia('(min-width: 768px)')

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    homeToolbar: {
        backgroundColor: colors.greyBlack,
        paddingRight: mediaQuery.matches ? theme.spacing(10) : 5,
        paddingLeft: mediaQuery.matches ? theme.spacing(10) : 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    backContainer: {
        // marginRight: theme.spacing(4),
    },
    icon: {
        fontSize: 30,
    },
    logo: {
        width: 40,
        height: 40,
        marginRight: theme.spacing(2),
    },
    fixedSpacing: {
        paddingTop: theme.spacing(18),
    },
    bannerContainer: {
        height: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: mediaQuery.matches ? 50 : 5,
        backgroundColor: colors.orange,
    },
    bannerText: {
        fontWeight: 500,
        fontSize: mediaQuery.matches ? 13 : 11,
    },
    hyperlink: {
        color: colors.greyBlack,
    },
    styleLinks: {
        paddingLeft: 20,
        paddingRight: 20,
        fontWeight: 600,
    }
}));
