import { User } from '../../types/user';
import { userConstants } from '../actions/types';

export interface UserState {
    user?: User;
}

const defaultState: UserState = {
    user: undefined,
};

interface UserAction {
    type: keyof typeof userConstants;
    payload: any;
}

export const userReducer = (state: UserState = defaultState, action: UserAction): UserState => {
    switch (action.type) {
        case userConstants.SET_USER:
            return { ...state, user: action.payload };
        case userConstants.LOGOUT:
            return defaultState;
        default:
            return state;
    }
};
