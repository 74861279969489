import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../Theme';

export const useStyles = makeStyles(theme => ({
    listTile: {
        '&:hover': {
            opacity: 0.8,
            cursor: 'pointer',
        },
    },
    titleBar: {
        height: 60,
        background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: colors.white,
        marginRight: theme.spacing(2),
    },
}));
