import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Page404 from './pages/errors/404';
import Scene from './pages/scene/Scene';
import Public from './pages/tour/Public';
import MyTours from './pages/tour/MyTours';
import Tour from './pages/tour/Tour';

import RouteChangeTracker from './services/RouteChangeTracker';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/mytours" />
                </Route>
                <Route exact path="/mytours">
                    <MyTours />
                </Route>
                <Route path="/public/tour/:tourID/scene/:sceneID">
                    <Scene />
                </Route>
                <Route path="/public/tour/:tourID/scene">
                    <Scene />
                </Route>
                <Route path="/public/tour/:tourID">
                    <Tour />
                    <RouteChangeTracker />
                </Route>
                <Route path="/tour/:tourID/scene/:sceneID">
                    <Scene />
                </Route>
                <Route path="/tour/:tourID/scene">
                    <Scene />
                </Route>
                <Route path="/tour/:tourID">
                    <Tour />
                    <RouteChangeTracker />
                </Route>
                {/* This is the route to create a Tour */}
                <Route path="/tour">
                    <Tour />
                </Route>
                <Route path="/public">
                    <Public />
                </Route>
                <Route path="/tours">
                    <Public />
                </Route>
                <Route path="*">
                    <Page404 />
                    <RouteChangeTracker />
                </Route>
            </Switch>
        </Router>
    );
};

export default Routes;
