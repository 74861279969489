import { makeStyles } from '@material-ui/core/styles';

import { colors } from './Theme';

export const appStyles = makeStyles(() => ({
    page: {
        backgroundColor: colors.blueGrey,
        minHeight: '100vh',
    },
    centeredPage: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
