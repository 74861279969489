import { createTheme, ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import React from 'react';

export const colors = {
    orange: '#F89726',
    white: '#FFFFFF',
    blueGrey: '#E8EAED',
    greyLight: '#FAFAFA',
    greyMedium: '#828282',
    greyNavy: '#202124',
    greyBlack: '#211E1E',
    tealBlue: '#3396C5',
    tealGreen: '#609D64',
    green: '#4DE292',
    red: '#D9512C',
};

export const theme = createTheme({
    palette: {
        primary: {
            main: colors.orange,
        },
        secondary: {
            main: colors.white,
        },
    },
    spacing: 5,
    typography: {
        fontSize: 14,
        fontFamily: 'Poppins',
        subtitle1: {
            color: colors.greyNavy,
        },
        subtitle2: {
            fontSize: 12,
            color: colors.greyMedium,
        },
    },
});

export const ThemeProvider = ({ children }: any) => (
    <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
);
