import { Hotspot } from '../../types/tour';
import { hotspotConstants } from '../actions/types';

export interface HotspotState {
    loading: boolean;
    hotspot?: Hotspot;
    list?: Hotspot[];
}

const defaultState: HotspotState = {
    loading: false,
    hotspot: undefined,
};

type Actions = keyof typeof hotspotConstants;
interface HotspotAction {
    type: Actions;
    payload: any;
}

export const hotspotReducer = (
    state: HotspotState = defaultState,
    action: HotspotAction
): HotspotState => {
    switch (action.type) {
        case hotspotConstants.LOADING_HOTSPOTS:
            return { ...state, loading: true };
        case hotspotConstants.CREATE_HOTSPOT:
            return { ...state, hotspot: action.payload, loading: false };
        case hotspotConstants.FETCH_HOTSPOTS:
            return { ...state, list: action.payload, loading: false };
        case hotspotConstants.UPDATE_HOTSPOT:
            return { ...state, hotspot: action.payload, loading: false };
        case hotspotConstants.DELETE_HOTSPOT:
            return { ...state, loading: false };
        default:
            return state;
    }
};
