import { Box, Typography } from '@material-ui/core';
import React from 'react';

import { appStyles } from '../../App.style';

function Page404() {
    const classes = appStyles();
    return (
        <Box className={classes.centeredPage}>
            <Typography variant="h1">404 Not Found</Typography>
            <Typography variant="h4">These are not the tours you are looking for</Typography>
        </Box>
    );
}

export default Page404;
