import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react';
// import { Button } from '@material-ui/core';
// import { Auth } from 'aws-amplify';
import React from 'react';

// import config from '../../config/app';
import { useStyles } from './Auth.style';

// const { clientID } = config.google;

function CustomAuthenticator() {
    const classes = useStyles();

    return (
        <div className={classes.bgImage}>
            <AmplifyAuthenticator>
                <AmplifySignIn
                    slot="sign-in"
                    headerText="Sign into your ExpeditionsPro account"
                    formFields={[{ type: 'username' }, { type: 'password' }]}
                    // federated={{ googleClientId: clientID }}
                >
                    <div slot="federated-buttons">
                        {/* <Button
                            className={classes.googleButton}
                            onClick={() => Auth.federatedSignIn({ provider: 'Google' } as any)}
                        /> */}
                    </div>
                </AmplifySignIn>
                <AmplifySignUp
                    slot="sign-up"
                    formFields={[{ type: 'username' }, { type: 'password' }, { type: 'email' }]}
                />
            </AmplifyAuthenticator>
        </div>
    );
}

export default CustomAuthenticator;
