import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    InputBase,
    Modal,
    Fade,
    Backdrop,
    Paper,
    Popover,
    Typography,
    Link,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { Search, Info as InfoIcon } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { SearchResults, Asset } from '../../types/cities360';

import PrimaryButton from '../button/Primary';
import UploadDropzone from '../dropzone/Dropzone';
import { useStyles } from './SceneDialog.style';

import config from '../../config/app';
const stock360 = config.stock.cities360;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number | string;
    value: number | string;
    style?: React.CSSProperties;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, style } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={style}
        >
        {value === index && children}
        </div>
    );
}

function SceneDialog({ onClose, onAddFile, onPrepopulate }: SceneDialogProps) {

    const classes = useStyles();

    const [newSceneImage, setNewSceneImage] = useState<BinaryType | Blob>();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [searchValue, setSearchValue] = useState('');
    const [cities360Search, setCities360Search] = useState<SearchResults | null>(null);
    const [asset, setAsset] = useState<Asset | null>(null);
    const [fileBlob, setFileBlob] = useState('');
    const [page, setPage] = React.useState<number>(1);
    const [anchorPopover, setAnchorPopover] = React.useState<HTMLButtonElement | null>(null);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        onAdd()
        setOpen(false);
    }

    useEffect(() => {
        onSearchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page])

    const onAdd = () => {
        const settings = {
            title: asset?.title,
            location: `${asset?.location.city} ${asset?.location.country}`,
            description: asset?.description,
            credits: asset?.credits,
        }
        onAddFile(newSceneImage, settings);
    };

    const onDropFile = (file: BinaryType) => {
        setNewSceneImage(file);
    };

    const onSearchList = async () => {
        if(searchValue) {
            setCities360Search(null)
            setSelectedTab(1)
            const searchURL = `${stock360.apiURL}/search/${searchValue}*?page_number=${page}`

            const response = await axios.get(searchURL, {
                headers: { 'Authorization': `Basic ${stock360.apiKey}` } });
            const search = response.data
            if(search.pagination.total_results > 0){   // add ' / 360cities.net` to credits
                search.results.map((item: Asset, idx: number) => search.results[idx].credits = `${item.author_name} / 360cities.net`) }
            setCities360Search(search) }
    };

    const getImage = async () => {
        
        if(asset){   // This fecth will return a JSON with an AWS S3 bucket direct URL
            fetch(asset.equirect4k ,{ headers: { 'Authorization': `Basic ${stock360.apiKey}` } } )
                .then( resp => resp.json())
                .then( resp2 => { // it needs to re-fetch to get imageBlob
                    fetch( resp2.url )
                        .then( resp3 => resp3.blob())
                        .then( imageBlob => {
                            setFileBlob(URL.createObjectURL(imageBlob))
                            setNewSceneImage(imageBlob);
                            setSelectedTab(0)
                        })
                }) }
    };

    const getLocation = async () => {

        if(asset){   // https://geocode.xyz/51.519198343163,-0.12667536735534668?geoit=json&auth=237305550962415483845x78132
            fetch(`${config.geocode.apiURL}/${asset.location.latitude},${asset.location.longitude}?geoit=json&auth=${config.geocode.apiKey}`)
                .then( resp => resp.json())
                .then( json => {
                    asset.location.city = (json.city !== undefined) ? json.city : ''
                    asset.location.country = (json.country !== undefined) ? json.country : ''
                    setAsset(asset)
                } ) }
    }

    return (
        <Dialog
            open={true}
            maxWidth={'lg'}
            fullWidth={true}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={classes.searchHeader}>
                <DialogTitle id="alert-dialog-title">Upload or Search for an image</DialogTitle>
                <div className={classes.searchWrapper}>
                    <Paper className={classes.searchBar}>
                        <InputBase
                            className={classes.searchInput}
                            value={searchValue}
                            onChange={ e => setSearchValue(e.target.value) }
                            onKeyPress={ e => { if(e.key === 'Enter' ) { setPage(1); onSearchList() } } }
                            placeholder="Search Images"
                        />
                        <Divider className={classes.divider} orientation="vertical" />
                        <IconButton
                            type="submit"
                            className={classes.iconButton}
                            onClick={() => { setPage(1); onSearchList(); } }
                        >
                            <Search />
                        </IconButton>
                    </Paper>
                </div>
            </div>

            <TabPanel value={selectedTab} index={0}>
                <DialogContent>
                    <UploadDropzone fileType="image360" onDropFile={onDropFile} currentFile={fileBlob} />
                </DialogContent>
            </TabPanel>

            <TabPanel style={{ minHeight: 566, minWidth: 800 }} value={selectedTab} index={1}>
                <DialogContent>
                    { cities360Search && cities360Search.pagination.total_results === 0 && 
                        <Typography variant="subtitle1" align="center" style={{marginTop: 100}} >Sorry, no results, please change your search terms</Typography>
                    }
                    { cities360Search &&
                        <ImageList gap={12} rowHeight={140} className={classes.imageList} cols={4}>
                        { cities360Search.results.map((item: Asset) => (
                            <ImageListItem key={item.thumbnails[3].url} cols={2}>
                                <img
                                    src={item.thumbnails[3].url}
                                    alt={item.title}
                                    onClick={() => { setAsset(item); getLocation(); getImage();} }
                                />
                                <ImageListItemBar
                                    title={item.title}
                                    subtitle={<span>by: {item.credits}</span>}
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    actionIcon={
                                        <IconButton
                                            aria-label={`info about ${item.title}`}
                                            className={classes.icon}
                                            onClick={ e => setAnchorPopover(e.currentTarget)}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    }
                                    />
                                <Popover
                                    id={Boolean(anchorPopover)? 'simple-popover' : undefined }
                                    open={Boolean(anchorPopover)}
                                    anchorEl={anchorPopover}
                                    onClose={() => setAnchorPopover(null)}
                                    anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                >
                                    <Typography className={classes.infoText}>{item.description}</Typography>
                                </Popover>
                            </ImageListItem>
                        )) }
                        </ImageList>
                    }
                    { cities360Search && cities360Search.pagination.total_pages > 1 &&
                        <Pagination
                            count={cities360Search.pagination.total_pages}
                            page={page}
                            size="small"
                            style={{marginLeft: 450, marginTop: 26}}
                            onChange={(e: React.ChangeEvent<unknown>, value: number) => setPage(value) }
                        />
                    }
                </DialogContent>

            </TabPanel>

            <DialogActions>
                { selectedTab === 1 &&
                    <PrimaryButton disabled={!selectedTab} onClick={() => { setSelectedTab(0); setPage(1); setSearchValue('');} }>
                        Select your Image
                    </PrimaryButton>
                }
                <PrimaryButton onClick={() => onClose()}>Cancel</PrimaryButton>
                <PrimaryButton disabled={!newSceneImage} onClick={handleOpen}>
                    Load
                </PrimaryButton>
            </DialogActions>

            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography variant="h5">Test for Beta modal</Typography>
                        <br /><br />
                        <Typography>
                            This is a beta preview of one of our soon to be released Premium Features.
                        </Typography>
                        <Typography>
                            We would love to get your feedback, please click
                            <Link
                                target="_blank"
                                href={config.feedbackUrl}
                                underline="none"
                                className={classes.styleLinks}
                                >
                                    here
                            </Link>
                            to let us know what you think.
                        </Typography>
                        <PrimaryButton
                                className={classes.btnOK}
                                onClick={handleClose}
                            >
                                [OK]
                        </PrimaryButton>
                    </div>
                </Fade>
            </Modal>

        </Dialog>
    );
}

type SceneDialogProps = {
    onClose: Function;
    onAddFile: Function;
    onPrepopulate: Function;
};

export default SceneDialog;
