import axios from 'axios';

import config from '../../config/app';
import { Media } from '../../types/files';
import { AsyncThunkAction } from '../../types/thunk';
import { Hotspot } from '../../types/tour';
import { hotspotConstants } from './types';
import { getAuthOptions } from './user';

const baseUrl = config.apiUrl + '/tour/private';

const fetchHotspots = (tourID: string, photosphereID: string): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: hotspotConstants.LOADING_HOTSPOTS,
            });

            const url = `${baseUrl}/${tourID}/photosphere/${photosphereID}/hotspot`;
            const hotspots = await axios.get(url, options);

            dispatch({
                type: hotspotConstants.FETCH_HOTSPOTS,
                payload: hotspots.data,
            });
        } catch (error) {
            console.log(error);
            console.log('Error fetching hotspots');
        }
    };
};

const createHotspot = (tourID: string, photosphereID: string, data: Hotspot): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: hotspotConstants.LOADING_HOTSPOTS,
            });

            const url = `${baseUrl}/${tourID}/photosphere/${photosphereID}/hotspot`;
            const hotspot = await axios.post(url, data, options);

            dispatch({
                type: hotspotConstants.CREATE_HOTSPOT,
                payload: hotspot.data,
            });

            return hotspot.data;
        } catch (error) {
            console.log(error);
            console.log('Error creating hotspot');
        }
    };
};

const updateHotspot = (
    tourID: string,
    photosphereID: string,
    hotspotID: string,
    data: Hotspot
): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: hotspotConstants.LOADING_HOTSPOTS,
            });

            const url = `${baseUrl}/${tourID}/photosphere/${photosphereID}/hotspot/${hotspotID}`;
            const hotspot = await axios.put(url, data, options);

            setTimeout(() => {  
                dispatch({
                    type: hotspotConstants.UPDATE_HOTSPOT,
                    payload: hotspot.data,
                });
            }, 1000);
        } catch (error) {
            console.log(error);
            console.log('Error updating hotspot');
        }
    };
};

const deleteHotspot = (
    tourID: string,
    photosphereID: string,
    hotspotID: string
): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: hotspotConstants.LOADING_HOTSPOTS,
            });

            const url = `${baseUrl}/${tourID}/photosphere/${photosphereID}/hotspot/${hotspotID}`;
            await axios.delete(url, options);

            dispatch({
                type: hotspotConstants.DELETE_HOTSPOT,
            });
        } catch (error) {
            console.log(error);
            console.log('Error deleting hotspot');
        }
    };
};

const deleteHotspotMedia = (
    tourID: string,
    photosphereID: string,
    hotspotID: string,
    media: Media
): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            let file: string;
            if (media === 'HS_OVERLAY_IMAGE') file = 'image_upload';
            else if (media === 'HS_NARRATION_AUDIO') file = 'narration_audio_upload';
            // eslint-disable-next-line no-throw-literal
            else throw 'Unrecognised media type';

            dispatch({
                type: hotspotConstants.LOADING_HOTSPOTS,
            });

            const url = `${baseUrl}/${tourID}/photosphere/${photosphereID}/hotspot/${hotspotID}/${file}`;
            await axios.delete(url, options);

            dispatch({
                type: hotspotConstants.DELETE_HOTSPOT,
            });
        } catch (error) {
            console.log(error);
            console.log('Error deleting hotspot');
        }
    };
};

export const HotspotActions = {
    fetchHotspots,
    createHotspot,
    updateHotspot,
    deleteHotspot,
    deleteHotspotMedia,
};
