/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    FormControl,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {
    Add,
    Delete,
    Error,
    GridOff,
    Image,
    RecordVoiceOver,
    VoiceOverOff,
} from '@material-ui/icons';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import PrimaryButton from '../../components/button/Primary';
import RaisedButton from '../../components/button/Raised';
import AudioDialog from '../../components/dialog/AudioDialog';
import ImageDialog from '../../components/dialog/ImageDialog';
import CounterText from '../../components/text/CounterText';
import { HotspotActions } from '../../redux/actions/hotspot';
import { RootState } from '../../redux/store';
import { uploadFileToS3 } from '../../services/s3';
import { getImage, invertYawPosition, sortArray } from '../../services/utils';
import { Media } from '../../types/files';
import { Hotspot, ImageData } from '../../types/tour';
import { useStyles } from './Hotspots.style';

function Hotspots({
    hotspots,
    isPublic,
    pitch,
    yaw,
    tour,
    photosphere,
    loading,
    selectedHotspotID,
    onSelectHotspot,
    createHotspot,
    updateHotspot,
    deleteHotspot,
    deleteHotspotMedia,
    onUpdate,
}: HotspotProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const [showImageDialog, setShowImageDialog] = useState(false);
    const [showAudioDialog, setShowAudioDialog] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [submit, setSubmit] = useState(false);

    const tourID = tour?.id;
    const photosphereID = photosphere?.id;

    useEffect(() => {
        if (selectedHotspotID && hotspots?.length) {
            const selected = hotspots.find(({ id }) => id === selectedHotspotID);
            if (selected) populateHotspot(selected);
        }
    }, [selectedHotspotID]);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        if (name === 'hotspot-title') setTitle(value);
        else if (name === 'hotspot-description') setDescription(value);
    };

    const populateHotspot = (hotspot: Hotspot) => {
        setTitle(hotspot.title);
        setDescription(hotspot.description ?? '');
    };

    const onClickHotspot = (hotspot: Hotspot, withRefresh = false) => {
        populateHotspot(hotspot);
        onSelectHotspot(hotspot, withRefresh);
    };

    const onAddHotpot = async () => {
        if (!tourID || !photosphereID) return;

        const hotspot = await createHotspot(tourID, photosphereID, {
            title: 'Untitled hotspot',
            pitch: pitch,
            yaw: invertYawPosition(yaw),
            order: (hotspots?.length ?? 0) + 1,
        });

        onClickHotspot(hotspot);
        onUpdate();
    };

    const onSubmit = async (hotspot: Hotspot) => {
        setSubmit(true);
        if (!title) return;
        if (!tourID || !photosphereID || !hotspot?.id) return;

        await updateHotspot(tourID, photosphereID, hotspot.id, {
            title,
            description,
            pitch: hotspot.pitch,
            yaw: hotspot.yaw,
        });

        onUpdate();
    };

    const onReposition = async (hotspot: Hotspot) => {
        if (!title) return;
        if (!tourID || !photosphereID || !hotspot?.id) return;

        await updateHotspot(tourID, photosphereID, hotspot.id, {
            title: hotspot.title ?? title,
            description: hotspot.description ?? description,
            pitch: pitch,
            yaw: invertYawPosition(yaw),
        });

        hotspot.pitch = pitch;
        hotspot.yaw = invertYawPosition(yaw);

        onSelectHotspot(hotspot);
        onUpdate();
    };

    const onRemoveMedia = async (hotspot: Hotspot, media: Media) => {
        if (!tourID || !photosphereID || !hotspot?.id) return;

        await deleteHotspotMedia(tourID, photosphereID, hotspot.id, media);
        onUpdate();
    };

    const uploadFile = async (file: BinaryType, media: Media) => {
        const hotspotID = selectedHotspotID;
        if (!tourID || !photosphereID || !hotspotID) return;

        const extension = media === 'HS_OVERLAY_IMAGE' ? 'png' : 'mp3';
        const filename = `tour_${tourID}_scene_${photosphereID}_hotspot_${hotspotID}_${media}.${extension}`;

        await uploadFileToS3({
            tourID,
            photosphereID,
            hotspotID,
            media,
            filename,
            file,
        });

        if (media === 'HS_OVERLAY_IMAGE') toggleImageDialog();
        else toggleAudioDialog();

        onUpdate();
    };

    const onDeleteClick = async (hotspotID: string) => {
        handleClose();
        if (!tourID || !photosphereID) return;

        await deleteHotspot(tourID, photosphereID, hotspotID);

        onUpdate();
    };

    const toggleImageDialog = () => {
        setShowImageDialog(!showImageDialog);
    };

    const toggleAudioDialog = () => {
        setShowAudioDialog(!showAudioDialog);
    };

    const renderAudio = (hotspot: Hotspot) => {
        const narration = hotspot?.narration_audio;
        if (!narration) return null;

        return (
            <div>
                <Typography variant="subtitle2">Scene narration</Typography>
                <audio src={narration} controls controlsList={'nodownload'} />
            </div>
        );
    };

    const renderHotspotControls = (hotspot: Hotspot) => {
        const image = getImage(hotspot?.image, 'thumbnail');
        const narration = hotspot?.narration_audio;

        return (
            <div className={classes.hotspotActionsContainer}>
                <Tooltip title={(image ? 'Remove' : 'Add') + ' image overlay'}>
                    <span>
                        <IconButton
                            aria-label={`info about`}
                            className={'material-icons'}
                            disabled={isPublic}
                            onClick={() =>
                                image ? onRemoveMedia(hotspot, 'HS_OVERLAY_IMAGE') : toggleImageDialog()
                            }
                        >
                            {image ? <GridOff /> : <Image />}
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title={(narration ? 'Remove' : 'Add') + ' sight narration'}>
                    <span>
                        <IconButton
                            aria-label={`info about`}
                            className={'material-icons'}
                            disabled={isPublic}
                            onClick={() =>
                                narration
                                    ? onRemoveMedia(hotspot, 'HS_NARRATION_AUDIO')
                                    : toggleAudioDialog()
                            }
                        >
                            {narration ? <VoiceOverOff /> : <RecordVoiceOver />}
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        );
    };

    const renderIcon = (hotspotImage?: ImageData) => {
        const image = getImage(hotspotImage, 'thumbnail');
        if (image) return <Image className={classes.icon} />;
        return <Error className={classes.icon} />;
    };

    const renderHotspot = (hotspot: Hotspot) => {
        return (
            <PrimaryButton
                key={`hotspot-${hotspot.id}`}
                className={classes.hotspotButton}
                startIcon={renderIcon(hotspot.image)}
                onClick={() => onClickHotspot(hotspot, true)}
            >
                {hotspot.title}
            </PrimaryButton>
        );
    };

    const renderSelectedHotspot = (hotspot: Hotspot) => {
        const hotspotID = hotspot.id;
        if (!hotspotID) return;

        return (
            <FormControl key={`selected-hs-${hotspotID}`} className={classes.selectedHotspot}>
                <div className={classes.formWrapper}>
                    {renderIcon(hotspot.image)}

                    <div className={classes.titleContainer}>
                        <TextField
                            id="hotspot-title"
                            name="hotspot-title"
                            value={title}
                            fullWidth={true}
                            onChange={handleChange}
                            error={submit && !title}
                            color="primary"
                            multiline={true}
                            disabled={isPublic}
                            inputProps={{
                                maxLength: 50,
                            }}
                        />

                        <CounterText text={title} max={50} />
                    </div>

                    <div>
                        <IconButton
                            aria-label={`info about `}
                            aria-controls={`hotspot-${hotspotID}`}
                            aria-haspopup="true"
                            onClick={handleMenu}
                            className={[classes.icon, 'material-icons'].join(' ')}
                        >
                            more_vert
                        </IconButton>

                        <Menu
                            id={`menu-${hotspotID}`}
                            anchorEl={anchorEl}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={menuOpen}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => onDeleteClick(hotspotID)} disabled={isPublic}>
                                <ListItemIcon>
                                    <Delete />
                                </ListItemIcon>
                                Delete point of interest
                            </MenuItem>
                        </Menu>
                    </div>
                </div>

                <TextField
                    id="hotspot-description"
                    name="hotspot-description"
                    value={description}
                    onChange={handleChange}
                    placeholder="Describe this point of interest"
                    color="primary"
                    fullWidth={true}
                    multiline={true}
                    disabled={isPublic}
                    inputProps={{
                        maxLength: 300,
                    }}
                />

                <CounterText text={description} max={300} />

                {renderAudio(hotspot)}

                <Box className={classes.formWrapper}>
                    {renderHotspotControls(hotspot)}
                    {!isPublic && (
                        <PrimaryButton loading={loading} onClick={() => onSubmit(hotspot)}>
                            Update
                        </PrimaryButton>
                    )}
                </Box>

                {!isPublic && (
                    <RaisedButton
                        className={classes.repositionButton}
                        onClick={() => onReposition(hotspot)}
                    >
                        Reposition Hotspot
                    </RaisedButton>
                )}
            </FormControl>
        );
    };

    const sortedHotspots = sortArray(hotspots ?? [], 'order');

    return (
        <div>
            {sortedHotspots.map(hotspot => {
                if (hotspot.id === selectedHotspotID) {
                    return renderSelectedHotspot(hotspot);
                }
                return renderHotspot(hotspot);
            })}

            {!isPublic && (
                <PrimaryButton
                    className={classes.newHotspotButton}
                    onClick={onAddHotpot}
                    startIcon={<Add />}
                >
                    Add point of interest
                </PrimaryButton>
            )}

            {showImageDialog && <ImageDialog onAddFile={uploadFile} onClose={toggleImageDialog} />}

            {showAudioDialog && (
                <AudioDialog
                    audioType={'HS_NARRATION_AUDIO'}
                    onAddFile={uploadFile}
                    onClose={toggleAudioDialog}
                />
            )}
        </div>
    );
}

const mapState = (state: RootState) => {
    return {
        loading: state.hotspot.loading,
        tour: state.tour.tour,
        photosphere: state.photosphere.photosphere,
    };
};

const mapDispatch = {
    createHotspot: (tourID: string, photosphereID: string, data: Hotspot) =>
        HotspotActions.createHotspot(tourID, photosphereID, data),
    updateHotspot: (tourID: string, photosphereID: string, hotspotID: string, data: Hotspot) =>
        HotspotActions.updateHotspot(tourID, photosphereID, hotspotID, data),
    deleteHotspot: (tourID: string, photosphereID: string, hotspotID: string) =>
        HotspotActions.deleteHotspot(tourID, photosphereID, hotspotID),
    deleteHotspotMedia: (tourID: string, photosphereID: string, hotspotID: string, media: Media) =>
        HotspotActions.deleteHotspotMedia(tourID, photosphereID, hotspotID, media),
};

const connector = connect(mapState, mapDispatch);
type HotspotProps = ConnectedProps<typeof connector> & {
    hotspots?: Hotspot[];
    onUpdate: Function;
    selectedHotspotID?: string;
    onSelectHotspot: Function;
    isPublic?: boolean;
    pitch: number;
    yaw: number;
};

export default connector(Hotspots);
