import axios from 'axios';

import config from '../../config/app';
import { AsyncThunkAction } from '../../types/thunk';
import { FetchToursParams, Tour } from '../../types/tour';
import { tourConstants } from './types';
import { getAuthOptions } from './user';

const privateUrl = config.apiUrl + '/tour/private';
const publicUrl = config.apiUrl + '/tour/public';

const fetchTours = ({
    page,
    size,
    search,
    category_id,
    isPublic,
}: FetchToursParams): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = isPublic ? {} : await getAuthOptions();

            dispatch({
                type: tourConstants.LOADING_TOURS,
            });

            const baseUrl = isPublic ? publicUrl : privateUrl;
            let url = `${baseUrl}?pageNumber=${page}&pageSize=${size}`;
            if (search) url += `&search=${search}`;
            if (category_id) url += `&category_id=${category_id}`;

            const tours = await axios.get(url, options);

            dispatch({
                type: tourConstants[isPublic ? 'FETCH_PUBLIC_TOURS' : 'FETCH_TOURS'],
                payload: tours.data,
            });
        } catch (error) {
            console.log(error);
            console.log('Error fetching tours');
        }
    };
};

const checkForPhotosphere = async (tourID: string, sceneID: string, isPublic: boolean) => {
    const options = isPublic ? {} : await getAuthOptions();
    const baseUrl = isPublic ? publicUrl : privateUrl;

    const resp = await axios.get(`${baseUrl}/${tourID}`, options);
    const tour: Tour = resp.data;
    const selected = tour?.photospheres?.find(({ id }) => id === sceneID);

    return selected?.image?.medium;
};

const checkForThumbnail = async (tourID: string, sceneID: string, isPublic: boolean) => {
    const options = isPublic ? {} : await getAuthOptions();
    const baseUrl = isPublic ? publicUrl : privateUrl;

    const resp = await axios.get(`${baseUrl}/${tourID}`, options);
    const tour: Tour = resp.data;
    const selected = tour?.photospheres?.find(({ id }) => id === sceneID);

    return selected?.image?.thumbnail;
};

const fetchTour = (tourID: string, isPublic: boolean): AsyncThunkAction => {
    return async dispatch => {
        try {
            // placeholder for isPublic :: it will be used when retriving secure tours
            // if(isPublic) null;

            dispatch({
                type: tourConstants.LOADING_TOURS,
            });

            const tour = await axios.get(`${publicUrl}/${tourID}`);

            dispatch({
                type: tourConstants.FETCH_TOUR,
                payload: tour.data,
            });
            return tour;

        } catch (error) {
            console.log('Error fetching tour', error);
        }
    };
};

const createTour = (data: Tour): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: tourConstants.LOADING_TOURS,
            });

            const tour = await axios.post(privateUrl, data, options);

            dispatch({
                type: tourConstants.CREATE_TOUR,
                payload: tour.data,
            });

            return tour.data;

        } catch (error) {
            console.log('Error creating tour', error);
        }
    };
};

const updateTour = (tourID: string, data: Tour): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: tourConstants.LOADING_TOURS,
            });

            const tour = await axios.put(`${privateUrl}/${tourID}`, data, options);

            dispatch({
                type: tourConstants.UPDATE_TOUR,
                payload: tour.data,
            });
        } catch (error) {
            console.log('Error updating tour', error);
        }
    };
};

const deleteTour = (tourID: string): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            await axios.delete(`${privateUrl}/${tourID}`, options);

            dispatch({
                type: tourConstants.DELETE_TOUR,
            });
        } catch (error) {
            console.log('Error deleting tour', error);
        }
    };
};

export const TourActions = {
    fetchTours,
    fetchTour,
    createTour,
    updateTour,
    deleteTour,
    checkForPhotosphere,
    checkForThumbnail
};
