import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../Theme';

export const useStyles = makeStyles(theme => ({
    newHotspotButton: {
        width: '100%',
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(5),
        justifyContent: 'left',
    },
    hotspotButton: {
        width: '100%',
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        justifyContent: 'left',
        color: grey[700],
    },
    selectedHotspot: {
        width: '100%',
        backgroundColor: colors.blueGrey,
        padding: theme.spacing(3),
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    hotspotActionsContainer: {
        display: 'flex',
        justifyContent: 'row',
    },
    repositionButton: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    icon: {
        color: grey[700],
        width: 24,
        height: 24,
    },
    titleContainer: {
        width: '70%',
    },
}));
