import { combineReducers } from 'redux';

import { categoryReducer } from './category';
import { hotspotReducer } from './hotspot';
import { photosphereReducer } from './photosphere';
import { tourReducer } from './tour';
import { tourImportReducer } from './tourImport';
import { userReducer } from './user';

const rootReducer = combineReducers({
    tour: tourReducer,
    photosphere: photosphereReducer,
    hotspot: hotspotReducer,
    user: userReducer,
    category: categoryReducer,
    tourImport: tourImportReducer,
});

export default rootReducer;
