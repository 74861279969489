import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 320;

export const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    formSpacing: {
        marginBottom: theme.spacing(4),
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    infoTitle: {
        fontSize: 20,
    },
    formWrapper: {
        margin: theme.spacing(3),
    },
    audioSpacing: {
        paddingBottom: theme.spacing(3),
    },
    audioActionsContainer: {
        display: 'flex',
        justifyContent: 'row',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    startingButton: {
        position: 'fixed',
        top: 110,
        left: 60,
    },
}));
