/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core';
import React, { useCallback, useState, MouseEvent } from 'react';
import { useDropzone } from 'react-dropzone';

import { FileType, TypeContentConfig } from '../../types/files';
import PrimaryButton from '../button/Primary';
import { useStyles } from './Dropzone.style';

const GOOGLE_SEARCH_360 = 'https://www.google.com/search?q=360+image&source=lnms&tbm=isch&sa=X&ved=2ahUKEwj42vjaxfT9AhUXUsAKHQrCAi0Q_AUoAXoECAEQAw&biw=3840&bih=2009&dpr=1'

const UploadDropzone = ({ onDropFile, fileType, currentFile, isPublic }: DropzoneProps) => {
    const classes = useStyles();
    const [fileBlob, setFileBlob] = useState('');
    const [fileName, setFileName] = useState('');

    const typeContent: TypeContentConfig = {
        coverImage: {
            title: 'Cover photo',
            subtitle: 'Drop an image here',
            button: 'Select an image',
            fileTypes: '.jpeg, .jpg, .png',
            acceptTypes: 'image/jpeg, image/png',
            styleClass: classes.defaultZone,
        },
        image: {
            subtitle: 'Drop an image here',
            button: 'Select an image',
            fileTypes: '.jpeg, .jpg, .png',
            acceptTypes: 'image/jpeg, image/png',
            styleClass: classes.defaultZone,
        },
        image360: {
            subtitle: 'Drop a 360° image here',
            button: 'Select a 360° image',
            fileTypes: '.jpeg, .jpg, .png',
            acceptTypes: 'image/jpeg, image/png',
            styleClass: classes.image360Zone,
        },
        audio: {
            subtitle: 'Drop an audio file here',
            button: 'Select an audio file',
            fileTypes: '.mp3',
            acceptTypes: 'audio/mpeg',
            styleClass: classes.audioZone,
        },
        zip: {
            subtitle: 'Drop a zip file here',
            button: 'Select a zip file',
            fileTypes: '.zip',
            acceptTypes: '.zip',
            styleClass: classes.defaultZone,
        },
    };

    const content = typeContent[fileType];

    const onDrop = useCallback((acceptedFiles: any[]) => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                const binary = reader?.result;
                if (!binary) return;

                const blob = new Blob([binary]);
                const srcBlob = URL.createObjectURL(blob);

                setFileName(file.name);
                setFileBlob(srcBlob);
                onDropFile(blob);
            };

            reader.readAsArrayBuffer(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxSize: 524288000,
        accept: content.acceptTypes,
    });

    const renderUploadedFile = (filePreview: string) => {
        if (fileType === 'audio') {
            return <audio src={filePreview} autoPlay controls controlsList={'nodownload'} />;
        }

        if (fileType === 'zip') {
            return <Typography variant="h5">Ready to upload: {fileName}</Typography>;
        }

        return <img className={content.styleClass} alt="Upload file" src={filePreview} />;
    };

    const filePreview = fileBlob || currentFile;

    return (
        <div>
            <div
                {...getRootProps({
                    className: [classes.dropzone, content.styleClass].join(' '),
                })}
            >
                {!isPublic && <input {...getInputProps()} />}

                {filePreview ? (
                    renderUploadedFile(filePreview)
                ) : (
                    <div>
                        {content.title && <Typography variant="h5">{content.title}</Typography>}
                        <p>{content.subtitle}</p>
                        <p className={classes.tightText}>or</p>
                        <div className={classes.centeredButton}>
                            <PrimaryButton>{content.button}</PrimaryButton>
                        </div>
                        { fileType === 'image360' &&
                            <>
                                <p className={classes.tightText}>or</p>
                                <div className={classes.centeredButton}>
                                    <PrimaryButton onClick={ (e: MouseEvent<HTMLButtonElement>) => {
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            e.preventDefault();
                                            window.open( GOOGLE_SEARCH_360, '_blank');
                                        } }
                                    >
                                        Search Google for '360 Images'
                                    </PrimaryButton>
                                </div>
                            </>
                        }
                    </div>
                )}
            </div>

            <Typography className={classes.infoText}>
                Allowed file types: {content.fileTypes}
            </Typography>
        </div>
    );
};

type DropzoneProps = {
    onDropFile: Function;
    fileType: FileType;
    currentFile?: string;
    isPublic?: boolean;
};

export default UploadDropzone;
