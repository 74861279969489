import { Box, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Delete, MoreVert } from '@material-ui/icons';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import SceneButton from '../../components/button/Scene';
import DeleteConfirmDialog from '../../components/dialog/DeleteConfirmDialog';
import { PhotosphereActions } from '../../redux/actions/photosphere';
import { RootState } from '../../redux/store';
import { sortArray } from '../../services/utils';
import { Photosphere } from '../../types/tour';
import { useStyles } from './SceneSelector.style';

function SceneSelector({
    tour,
    photospheres,
    selectedSceneID,
    toggleSceneDialog,
    deletePhotosphere,
    clearPhotosphere,
    user,
}: PhotosphereProps) {
    const classes = useStyles();
    const history = useHistory();

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [deletingSceneID, setDeletingSceneID] = useState<null | string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const location = useLocation();
    const isPublic = location?.pathname.includes('public');
    const isOwner = (tour?.user_id === user?.attributes?.sub);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onAddScene = () => {
        if (!tour?.id) return;
        if (toggleSceneDialog) toggleSceneDialog();
        clearPhotosphere();

        const url = `/tour/${tour.id}/scene`;
        history.push(isPublic ? `/tours${url}` : url);
    };

    const onClickScene = (photosphereID?: string) => {
        if (!tour?.id) return;

        const url = `/tour/${tour.id}/scene/${photosphereID}`;
        history.push(isPublic ? `/tours${url}` : url);
    };

    const onClickTourHome = () => {
        if (!tour?.id) return;

        const url = `/tour/${tour.id}`;
        history.push(isPublic ? `/tours${url}` : url);
    };

    const onMoveLeft = (photosphereID?: string) => {
        if (!photospheres) return;
        let keyPosition = 0;
        photospheres.forEach((ps, index: number) => {
            if (ps.id === photosphereID) keyPosition = index;
        });

        if (keyPosition === 0) return;
        const photosphere = photospheres[keyPosition];
        photospheres[keyPosition] = photospheres[keyPosition - 1];
        photospheres[keyPosition - 1] = photosphere;
    };

    const onMoveRight = (photosphereID?: string) => {
        if (!photospheres) return;
        let keyPosition = 0;
        photospheres.forEach((ps, index: number) => {
            if (ps.id === photosphereID) keyPosition = index;
        });

        if (keyPosition + 1 >= photospheres.length) return;
        const photosphere = photospheres[keyPosition];
        photospheres[keyPosition] = photospheres[keyPosition + 1];
        photospheres[keyPosition + 1] = photosphere;
    };

    const deleteScene = (photosphereID: string) => {
        setDeletingSceneID(photosphereID);
        toggleDeleteConfirmDialog();
    };

    const toggleDeleteConfirmDialog = async (confirmDelete?: boolean) => {
        setShowDeleteConfirmDialog(!showDeleteConfirmDialog);
        if (confirmDelete) {
            if (!tour?.id || !deletingSceneID) return;

            await deletePhotosphere(tour?.id, deletingSceneID);
            history.push(`/tour/${tour?.id}`);
        }

        if (deletingSceneID) setDeletingSceneID(null);
    };

    const renderActionButtons = (photosphereID: string) => {
        return (
            <Box className={classes.actionContainer}>
                <Tooltip title="Delete scene">
                    <span>
                        <IconButton
                            aria-label={`info about`}
                            className={'material-icons'}
                            onClick={() => deleteScene(photosphereID)}
                        >
                            <Delete />
                        </IconButton>
                    </span>
                </Tooltip>

                {photosphereID === 'hide-temporarily' && (
                    <div>
                        <Tooltip title="Move scene options">
                            <span>
                                <IconButton
                                    aria-label={`info about`}
                                    className={'material-icons'}
                                    onClick={handleMenu}
                                >
                                    <MoreVert />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Menu
                            id="menu-drawer"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={onClose}
                        >
                            <MenuItem onClick={() => onMoveLeft(photosphereID)}>
                                <ListItemIcon>
                                    <ChevronLeft />
                                </ListItemIcon>
                                Move left
                            </MenuItem>
                            <MenuItem onClick={() => onMoveRight(photosphereID)}>
                                <ListItemIcon>
                                    <ChevronRight />
                                </ListItemIcon>
                                Move right
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </Box>
        );
    };

    const renderScenes = (photosphere: Photosphere) => {
        if (!photosphere?.id) return null;
        if (!isOwner){ // Do not show scenes with no image unless isOwner
            if (!photosphere.image?.medium && !photosphere.streetview_pano_id) return null;
        }

        const isSelected = photosphere.id === selectedSceneID;

        return isSelected ? (
            <>
                <SceneButton
                    key={photosphere.id}
                    onClick={() => onClickScene(photosphere.id)}
                    className={classes.selectedScene}
                >
                    {photosphere.title}
                </SceneButton>
                {isOwner && renderActionButtons(photosphere.id)}
            </>
        ) : (
            <SceneButton
                key={photosphere.id}
                onClick={() => onClickScene(photosphere.id)}
                className={classes.unselectedScene}
            >
                {photosphere.title}
            </SceneButton>
        );
    };

    const renderAddButton = () => {
        if (!tour?.id || !isOwner) return null;
        return <SceneButton onClick={onAddScene}>Add scene</SceneButton>;
    };

    const sortedPhotospheres = sortArray(photospheres ?? [], 'order');

    return (
        <Box className={classes.container}>
            <SceneButton
                key="tour-details"
                onClick={onClickTourHome}
                className={classes.unselectedScene}
            >
                Tour Details
            </SceneButton>
            {sortedPhotospheres.map(renderScenes)}
            {renderAddButton()}

            {showDeleteConfirmDialog && (
                <DeleteConfirmDialog item="scene" onClose={toggleDeleteConfirmDialog} />
            )}
        </Box>
    );
}

const mapState = (state: RootState) => {
    return {
        user: state.user.user,
        tour: state.tour.tour,
    };
};

const mapDispatch = {
    clearPhotosphere: () => PhotosphereActions.clearPhotosphere(),
    deletePhotosphere: (tourID: string, photosphereID: string) =>
        PhotosphereActions.deletePhotosphere(tourID, photosphereID),
};

const connector = connect(mapState, mapDispatch);
type PhotosphereProps = ConnectedProps<typeof connector> & {
    page: 'tour' | 'scene';
    photospheres?: Photosphere[];
    selectedSceneID?: string;
    toggleSceneDialog?: Function;
};

export default connector(SceneSelector);
