import axios from 'axios';

import config from '../../config/app';
import { Media } from '../../types/files';
import { AsyncThunkAction, ThunkAction } from '../../types/thunk';
import { Photosphere } from '../../types/tour';
import { photosphereConstants } from './types';
import { getAuthOptions } from './user';

const privateUrl = config.apiUrl + '/tour/private';
const publicUrl = config.apiUrl + '/tour/public';

const fetchPhotospheres = (tourID: string, isPublic: boolean): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = isPublic ? {} : await getAuthOptions();

            dispatch({
                type: photosphereConstants.LOADING_PHOTOSPHERES,
            });

            const baseUrl = isPublic ? publicUrl : privateUrl;
            const url = `${baseUrl}/${tourID}/photosphere`;
            const photospheres = await axios.get(url, options);

            dispatch({
                type: photosphereConstants.FETCH_PHOTOSPHERES,
                payload: photospheres.data,
            });
        } catch (error) {
            console.log(error);
            console.log('Error fetching photospheres');
        }
    };
};

const fetchPhotosphere = (
    tourID: string,
    photosphereID: string,
    isPublic: boolean
): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = isPublic ? {} : await getAuthOptions();

            dispatch({
                type: photosphereConstants.LOADING_PHOTOSPHERES,
            });

            const baseUrl = isPublic ? publicUrl : privateUrl;
            const url = `${baseUrl}/${tourID}/photosphere/${photosphereID}`;
            const photosphere = await axios.get(url, options);

            dispatch({
                type: photosphereConstants.FETCH_PHOTOSPHERE,
                payload: photosphere.data,
            });
            return photosphere;
        } catch (error) {
            console.log(error);
            console.log('Error fetching photosphere');
        }
    };
};

const createPhotosphere = (tourID: string, data: Photosphere): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: photosphereConstants.LOADING_PHOTOSPHERES,
            });

            const url = `${privateUrl}/${tourID}/photosphere`;
            const photosphere = await axios.post(url, data, options);

            dispatch({
                type: photosphereConstants.CREATE_PHOTOSPHERE,
                payload: photosphere.data,
            });

            return photosphere?.data?.id;

        } catch (error) {
            console.log(error);
            console.log('Error creating photosphere');
        }
    };
};

const updatePhotosphere = (
    tourID: string,
    photosphereID: string,
    data: Photosphere
): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: photosphereConstants.LOADING_PHOTOSPHERES,
            });

            const url = `${privateUrl}/${tourID}/photosphere/${photosphereID}`;
            const photosphere = await axios.put(url, data, options);

            dispatch({
                type: photosphereConstants.UPDATE_PHOTOSPHERE,
                payload: photosphere.data,
            });
        } catch (error) {
            console.log(error);
            console.log('Error updating photosphere');
        }
    };
};

const deletePhotosphere = (tourID: string, photosphereID: string): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: photosphereConstants.LOADING_PHOTOSPHERES,
            });

            const url = `${privateUrl}/${tourID}/photosphere/${photosphereID}`;
            await axios.delete(url, options);

            dispatch({
                type: photosphereConstants.DELETE_PHOTOSPHERE,
            });
        } catch (error) {
            console.log(error);
            console.log('Error deleting photosphere');
        }
    };
};

const deletePhotosphereMedia = (
    tourID: string,
    photosphereID: string,
    media: Media
): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            let file: string;
            if (media === 'PS_SCENE_IMAGE') file = 'image_upload';
            else if (media === 'PS_AMBIENT_AUDIO') file = 'background_audio';
            else if (media === 'PS_NARRATION_AUDIO') file = 'narration_audio';
            // eslint-disable-next-line no-throw-literal
            else throw 'Unrecognised media type';

            dispatch({
                type: photosphereConstants.LOADING_PHOTOSPHERES,
            });

            const url = `${privateUrl}/${tourID}/photosphere/${photosphereID}/${file}`;
            await axios.delete(url, options);

            dispatch({
                type: photosphereConstants.DELETE_PHOTOSPHERE,
            });
        } catch (error) {
            console.log(error);
            console.log('Error deleting photosphere');
        }
    };
};

const clearPhotosphere = (): ThunkAction => {
    return dispatch => dispatch({ type: photosphereConstants.CLEAR_PHOTOSPHERE });
};

export const PhotosphereActions = {
    fetchPhotospheres,
    createPhotosphere,
    fetchPhotosphere,
    updatePhotosphere,
    clearPhotosphere,
    deletePhotosphere,
    deletePhotosphereMedia,
};
