/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, IconButton, InputBase, MenuItem, Paper, Select } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { CategoryActions } from '../../redux/actions/category';
import { RootState } from '../../redux/store';
import { useStyles } from './GlobalSearch.style';

function GlobalSearch({ onSearch, categories, fetchCategories }: GlobalSearchProps) {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState('');
    const [categoryID, setCategoryID] = useState<string>('');

    useEffect(() => {
        fetchCategories();
    }, []);

    const onSubmit = () => {
        onSearch(searchTerm, categoryID);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setSearchTerm(value);
    };

    const handleSelect = (event: ChangeEvent<any>) => {
        const { name, value } = event.target;
        if (name === 'category-filter') {
            const category = value === 'allCategories' ? '' : value;
            setCategoryID(category);
            onSearch(searchTerm, category);
        }
    };

    const handleKeyPress = ({ key }: { key: string }) => {
        if (key === 'Enter') onSubmit();
    };

    const getInputClass = () => {
        return searchTerm ? { root: classes.openedInput } : { focused: classes.openedInput };
    };

    return (
        <div className={classes.searchWrapper}>
            <Select
                id="category-filter"
                name="category-filter"
                labelId="category-filter-label"
                value={categoryID || 'allCategories'}
                onChange={handleSelect}
                color="primary"
                variant={'outlined'}
                className={classes.selectMenu}
                MenuProps={{ classes: { paper: classes.selectHeight } }}
            >
                <MenuItem key="allCategories" value="allCategories">
                    All Categories
                </MenuItem>

                {categories?.length &&
                    categories.map(({ id, value }) => (
                        <MenuItem key={id} value={id}>
                            {value}
                        </MenuItem>
                    ))}
            </Select>

            <Paper className={classes.searchBar}>
                <InputBase
                    className={classes.searchInput}
                    classes={getInputClass()}
                    placeholder="Search Tours"
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton type="submit" className={classes.iconButton} onClick={onSubmit}>
                    <Search />
                </IconButton>
            </Paper>
        </div>
    );
}

const mapState = (state: RootState) => {
    return {
        categories: state.category.list,
    };
};

const mapDispatch = {
    fetchCategories: () => CategoryActions.fetchCategories(),
};

const connector = connect(mapState, mapDispatch);
type GlobalSearchProps = ConnectedProps<typeof connector> & { onSearch: Function };

export default connector(GlobalSearch);
