import axios from 'axios';

import config from '../../config/app';
import { AsyncThunkAction } from '../../types/thunk';
import { tourImportConstants } from './types';
import { getAuthOptions } from './user';

const url = config.apiUrl + '/tour_import';

const fetchTourImports = (): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: tourImportConstants.LOADING_IMPORTS,
            });

            const tourImports = await axios.get(url, options);

            dispatch({
                type: tourImportConstants.FETCH_IMPORTS,
                payload: tourImports.data,
            });
        } catch (error) {
            console.log(error);
            console.log('Error fetching tour imports');
        }
    };
};

const fetchTourImport = (tourImportID: string): AsyncThunkAction => {
    return async dispatch => {
        try {
            const options = await getAuthOptions();

            dispatch({
                type: tourImportConstants.LOADING_IMPORTS,
            });

            const tourImport = await axios.get(`${url}/${tourImportID}`, options);

            dispatch({
                type: tourImportConstants.FETCH_IMPORT,
                payload: tourImport.data,
            });
        } catch (error) {
            console.log(error);
            console.log('Error fetching tour import');
        }
    };
};

export const TourImportActions = {
    fetchTourImports,
    fetchTourImport,
};
