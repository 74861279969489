import { TourImport } from '../../types/tour';
import { tourImportConstants } from '../actions/types';

export interface ImportState {
    loading: boolean;
    tourImport?: TourImport;
    list?: TourImport[];
}

const defaultState: ImportState = {
    loading: false,
    tourImport: undefined,
};

type Actions = keyof typeof tourImportConstants;
interface ImportAction {
    type: Actions;
    payload: any;
}

export const tourImportReducer = (
    state: ImportState = defaultState,
    action: ImportAction
): ImportState => {
    switch (action.type) {
        case tourImportConstants.LOADING_IMPORTS:
            return { ...state, loading: true };
        case tourImportConstants.FETCH_IMPORTS:
            return { list: action.payload, loading: false };
        case tourImportConstants.FETCH_IMPORT:
            return { ...state, tourImport: action.payload, loading: false };
        default:
            return state;
    }
};
