import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../Theme';

export const useStyles = makeStyles(theme => ({
    regularSpacing: {
        marginTop: theme.spacing(5),
    },
    tableHeader: {
        backgroundColor: colors.blueGrey,
    },
    success: {
        color: colors.tealGreen,
    },
    pending: {
        color: colors.orange,
    },
    failed: {
        color: colors.red,
    },
}));
