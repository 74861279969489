/* eslint-disable react-hooks/exhaustive-deps */
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { TourImportActions } from '../../redux/actions/tourImport';
import { RootState } from '../../redux/store';
import { uploadTourZipToS3 } from '../../services/s3';
import PrimaryButton from '../button/Primary';
import UploadDropzone from '../dropzone/Dropzone';
import { useStyles } from './Dialog.style';

function TourImportDialog({ onClose, tourImport, fetchTourImport }: TourImportProps) {
    const classes = useStyles();

    const [newZip, setNewZip] = useState<BinaryType>();
    const [tourImportID, setTourImportID] = useState<string>();
    const [loading, setLoading] = useState(false);

    const finishedStatuses = ['FAILED', 'SUCCESSFUL'];
    const statuses = {
        QUEUED: 'pending',
        PROCESSING: 'pending',
        FAILED: 'failed',
        SUCCESSFUL: 'success',
    };

    useEffect(() => {
        if (tourImportID) pollTourImport();
    }, [tourImportID]);

    useEffect(() => {
        // Every few seconds keep fetching tour import record to see it's status
        if (tourImportID && tourImport && !finishedStatuses.includes(tourImport.status)) {
            setTimeout(() => pollTourImport(), 2000);
        }
    }, [tourImport]);

    const onCloseDialog = () => {
        const isImported = tourImport?.status === 'SUCCESSFUL';
        onClose(isImported);
    };

    const pollTourImport = async () => {
        if (!tourImportID) return;
        await fetchTourImport(tourImportID);
    };

    const onUploadFile = async () => {
        if (!newZip) return;
        const filename = `tour_import_${Date.now()}.zip`;

        setLoading(true);
        const importResponse = await uploadTourZipToS3(filename, newZip);
        if (importResponse.id) setTourImportID(importResponse.id);
        setLoading(false);
    };

    const onDropFile = (file: BinaryType) => {
        setNewZip(file);
    };

    const getImportResult = () => {
        if (!tourImport) return null;

        const { status, error_message } = tourImport;

        if (error_message) {
            return <Typography className={classes.failed}>{error_message}</Typography>;
        } else if (statuses[status] === 'pending') {
            return <CircularProgress size={20} />;
        } else if (statuses[status] === 'success') {
            return <Done className={classes.success} />;
        }

        return null;
    };

    const renderImportStatus = () => {
        if (!tourImport) return null;

        const { id, filename, status } = tourImport;

        let statusStyle = classes.pending;
        if (statuses[status] === 'success') statusStyle = classes.success;
        else if (statuses[status] === 'failed') statusStyle = classes.failed;

        return (
            <TableContainer className={classes.regularSpacing} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell>Import ID</TableCell>
                            <TableCell>Filename</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={id}>
                            <TableCell component="th" scope="row">
                                {id}
                            </TableCell>
                            <TableCell>{filename}</TableCell>
                            <TableCell className={statusStyle}>{status}</TableCell>
                            <TableCell>{getImportResult()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Dialog
            open={true}
            maxWidth={'md'}
            fullWidth={true}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Import Tours</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" component={'span'} >
                    Upload your previously exported Tours from Google Expeditions.
                    <ol>
                        <li>Find your exported file which will be in the format: ‘takeout-20 ??????????????-0??.zip’</li>
                        <li>Double click this Zip file to open it</li>
                        <li>Double click the Takeout folder and then double click the Poly folder</li>
                        <li>You will then see a list of folders with the names of your Tours</li>
                        <li>Inside each of these folders you will find a file called tour.zip - this is the file you need to upload to ExpeditionsPro.</li>
                        <li>Drag this file to the window below then click Import to start the import process</li>
                        <li>Repeat this for each of your Tours</li>
                    </ol>
                </DialogContentText>

                {tourImport && tourImportID ? (
                    renderImportStatus()
                ) : (
                    <UploadDropzone fileType="zip" onDropFile={onDropFile} />
                )}
            </DialogContent>
            <DialogActions>
                {tourImportID ? (
                    <PrimaryButton onClick={onCloseDialog}>Close</PrimaryButton>
                ) : (
                    <>
                        <PrimaryButton onClick={onCloseDialog}>Cancel</PrimaryButton>
                        <PrimaryButton loading={loading} disabled={!newZip} onClick={onUploadFile}>
                            Import
                        </PrimaryButton>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

const mapState = (state: RootState) => {
    return {
        tourImport: state.tourImport.tourImport,
    };
};

const mapDispatch = {
    fetchTourImport: (tourImportID: string) => TourImportActions.fetchTourImport(tourImportID),
};

const connector = connect(mapState, mapDispatch);

type TourImportProps = ConnectedProps<typeof connector> & {
    onClose: Function;
};

export default connector(TourImportDialog);
