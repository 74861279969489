/* eslint-disable react-hooks/exhaustive-deps */
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { CssBaseline } from '@material-ui/core';
import Amplify, { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { appStyles } from './App.style';
import config from './config/app';
import CustomAuthenticator from './pages/auth/CustomAuthenticator';
import Terms from './pages/auth/Terms';
import { UserActions } from './redux/actions/user';
import { RootState } from './redux/store';
import Routes from './Routes';
import { ThemeProvider } from './Theme';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-187895049-1');
ReactGA.pageview(window.location.pathname + window.location.search);

Amplify.configure(config.awsConfig);

function App({ user, getCurrentUser, updateUser }: UserProps) {
    const classes = appStyles();

    useEffect(() => {
        onAuthUIStateChange((nextAuthState, authData) => {

            if (authData) getCurrentUser();
        });
    }, []);

    const onAcceptTerms = async () => {
        await updateUser({ 'custom:user_accepted_terms': '1' });
        await updateUser({ 'custom:user_accepted_terms': '1' });
    };

    const onCancelTerms = async () => {
        await Auth.signOut();
        await getCurrentUser();
    };

    const renderApp = () => {
        if (!user) {
            return <CustomAuthenticator />;
        }

        if (user.attributes?.['custom:user_accepted_terms'] !== '1') {
            return <Terms onAccept={onAcceptTerms} onCancel={onCancelTerms} />;
        }

        return (
            <div className="App">
                <Routes />
            </div>
        );
    };

    return (
        <ThemeProvider>
            <CssBaseline />
            <main className={classes.page}>{renderApp()}</main>
        </ThemeProvider>
    );
}

const mapState = (state: RootState) => {
    return {
        user: state.user.user,
    };
};

const mapDispatch = {
    getCurrentUser: () => UserActions.getCurrentUser(),
    updateUser: (data: Object) => UserActions.updateUser(data),
};

const connector = connect(mapState, mapDispatch);
type UserProps = ConnectedProps<typeof connector>;

export default connector(App);
