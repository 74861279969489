import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../Theme';

export const useStyles = makeStyles(theme => ({
    searchWrapper: {
        display: 'flex',
        justifyContent: 'row',
    },
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
    },
    selectMenu: {
        width: 200,
        borderRadius: 30,
        fontSize: 14,
        backgroundColor: colors.white,
        marginRight: theme.spacing(2),
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
    },
    selectHeight: {
        maxHeight: 250,
    },
    searchInput: {
        fontSize: 14,
    },
    openedInput: {
        width: 200,
    },
    iconButton: {
        padding: theme.spacing(2),
    },
    divider: {
        height: 28,
        margin: theme.spacing(1),
    },
}));
